import React from "react";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Logo from "../../../images/logo_despar.png"
import { LinkContainer } from "react-router-bootstrap";
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import Modal from "../../UI/Modal/CustomModal";
import Tac from "../../../pages/Tac";
import Privacy from "../../../pages/Privacy";

class TotemHeader extends React.Component {
    validateUsername = (user) => {
        let result = false;
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (user && user.username) {
            result = !regex.test(user.username)
            console.log(result);
        }
        return result;
    }

    render() {
        const storeId = localStorage.getItem("storeId")
        const link = storeId !== "undefined" ? `/app/totem?storeId=${storeId}` : "/app/totem"
        return (
            <>
                <Translation>
                    {t =>
                        <Navbar bg="light" expand="lg">
                            <a href={link}>
                                <img src={Logo} style={logoStyle} alt='logo' />
                            </a>
                            {/* 
                            <LinkContainer to="/totem">
                                <Nav.Link href="/totem">
                                    <img src={Logo} style={logoStyle} alt='logo' />
                                </Nav.Link>
                            </LinkContainer>
                             */}
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <Modal trigger={
                                        <LinkContainer to="#"><Nav.Link>{t("privacy")}</Nav.Link></LinkContainer>}>
                                        <Privacy isTotem={true} />
                                    </Modal>
                                    <Modal trigger={
                                        <LinkContainer to="#"><Nav.Link>{t("conditions")}</Nav.Link></LinkContainer>}>
                                        <Tac isTotem={true} />
                                    </Modal>
                                    {this.props.user && <LinkContainer to="/totem/profile"><Nav.Link
                                        href="/totem/profile">{t("profile")}</Nav.Link></LinkContainer>}
                                    {this.props.user &&
                                        <LinkContainer to="/totem/card"><Nav.Link href="/totem/card">{t("card")}</Nav.Link></LinkContainer>}
                                </Nav>
                                <Nav>
                                    {this.validateUsername(this.props.user) &&
                                        <LinkContainer to="/totem/upgrade">
                                            <Nav.Link to="/totem/upgrade">{t("profile_update")}</Nav.Link></LinkContainer>}
                                    {this.props.user &&
                                        <LinkContainer onClick={() => this.props.logout(this.props.history)}
                                            to="#"><Nav.Link to="#">{t("logout")}</Nav.Link></LinkContainer>}
                                    {!this.props.user &&
                                        <LinkContainer style={{ cursor: "pointer", borderRight: "1px solid green" }}
                                            to="/totem/login"><Nav.Link as="h4" to="/totem/login">
                                                <span className="brand-primary-text">{t("access_totem")}</span>
                                            </Nav.Link></LinkContainer>}
                                    {!this.props.user &&
                                        <LinkContainer style={{ cursor: "pointer" }} to="/totem/signup"><Nav.Link as="h4"
                                            to="/totem/login">
                                            <span className="brand-primary-text">{t("signup_totem")}</span>
                                        </Nav.Link></LinkContainer>}
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    }
                </Translation>
            </>
        );
    }
}

const logoStyle = {
    height: 'auto',
    width: '200px',

}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: (history) => dispatch(actionCreators.logout(history)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TotemHeader));
