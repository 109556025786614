import React, { Component } from "react"
// import SignupChoice from "./SignupChoice"
// import SignupLaTua from "./SignupLaTua"
import TotemSignupForm from "./SignupForm/TotemSignupForm"
import { authenticationService } from "../../services/basis/AuthenticationService"
import toaster from "../../utils/toaster"
import Signature from "../Signature/Signature"

export default class TotemSignup extends Component {

    state = {
        step: 2,
        hasLaTua: null,
        profile: {},
        formData: {}
    }

    hasLaTua = (answer) => {
        let newState = answer ? 1 : 2;

        this.setState({
            ...this.state,
            step: newState,
            hasLaTua: answer
        });
    }

    readLaTua = (cardCode, lastName, acceptLaTua) => {
        // load customer data from server
        authenticationService.laTua(cardCode, lastName)
            .then(response => {
                this.setState({
                    ...this.state,
                    profile: response.data,
                    step: 2
                });
            }).catch((error) => {
                toaster.error(error.message);
            })
    }

    signature = (data) => {
        this.setState({
            formData: data,
            step: 3
        })
    }

    backToForm = () => {
        this.setState({
            step: 2
        })
    }

    render() {
        return (
            <div className="signup-wrapper">
                {/*{this.state.step === 0 && <SignupChoice hasLaTua={this.hasLaTua} />}*/}
                {/*{this.state.step === 1 && <SignupLaTua readLaTua={this.readLaTua} />}*/}
                {this.state.step === 2 && <TotemSignupForm profile={this.state.profile} hasLaTua={this.state.hasLaTua} signature={this.signature} formData={this.state.formData} />}
                {this.state.step === 3 && <Signature formData={this.state.formData} backToForm={this.backToForm} />}
            </div>
        );
    }
}