import React from "react";
import { Translation } from 'react-i18next';

export default class StoreInfo extends React.Component {

    render() {
        const { selectedStore } = this.props;
        if (selectedStore) {
            return (
                <Translation>
                    {t =>
                        <div>
                            <div className="row">
                                {(selectedStore.phone || selectedStore.email || selectedStore.webUrl || selectedStore.notes) &&
                                    <div className="col-sm-12 mb-3">
                                        <h5>{t("contacts")}</h5>
                                        <div>
                                            {selectedStore.phone &&
                                                <div>
                                                    {t("storePhone")}: {selectedStore.phone}
                                                </div>
                                            }
                                            {selectedStore.email &&
                                                <div>
                                                    {t("storeEmail")}: <a href={"mailto:" + selectedStore.email}>{selectedStore.email}</a>
                                                </div>
                                            }
                                            {selectedStore.webUrl &&
                                                <div>
                                                    {t("webUrl")}: <br />
                                                    <a href={selectedStore.webUrl} target="_blank" rel="noopener noreferrer">{selectedStore.webUrl}</a>
                                                </div>
                                            }

                                            {selectedStore.notes &&
                                                <div>
                                                    {t("companyName")}: <br/>
                                                    {selectedStore.notes}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                <div className="col-md-6">
                                    {selectedStore.timetables &&
                                        <div className="mb-2">
                                            <h5>{t("time")}</h5>
                                            <div>
                                                {selectedStore.timetables.map((timetable, index) => (
                                                    <div key={index}>{timetable}</div>
                                                ))}
                                            </div>
                                        </div>
                                    }

                                    {selectedStore.openings &&
                                        <div className="mb-2">
                                            <h5>{t("openings")}</h5>
                                            <div>
                                                {selectedStore.openings.map((opening, index) => (
                                                    <div key={index}>{opening}</div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    {selectedStore.services &&
                                        <div className="mb-2">
                                            <h5>{t("services")}</h5>
                                            <div>
                                                {selectedStore.services.map((service, index) => (
                                                    <p key={index}>{service}</p>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-6">
                                    {selectedStore.departments &&
                                        <div className="mb-2">
                                            <h5>{t("departments")}</h5>
                                            <div>
                                                {selectedStore.departments.map((department, index) => (
                                                    <div key={index}>{department}</div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}



