import React, { Component } from "react"
// import SignupChoice from "./SignupChoice"
// import SignupLaTua from "./SignupLaTua"
import SignupForm from "./SignupForm/SignupForm"
import { authenticationService } from "../../services/basis/AuthenticationService"
import toaster from "../../utils/toaster"

export default class Signup extends Component {

    state = {
        step: 0,
        hasLaTua: null,
        profile: {}
    }

    hasLaTua = (answer) => {
        let newState = answer ? 1 : 2;

        this.setState({
            ...this.state,
            step: newState,
            hasLaTua: answer
        });
    }

    readLaTua = (cardCode, lastName, acceptLaTua) => {
        // load customer data from server
        authenticationService.laTua(cardCode, lastName)
            .then(response => {
                this.setState({
                    ...this.state,
                    profile: response.data,
                    step: 2
                });
            }).catch((error) => {
                toaster.error(error.message);
            })
    }

    render() {
        return (
            <div className="signup-wrapper">
                {/*{ this.state.step === 0 && <SignupChoice hasLaTua={this.hasLaTua} />}*/}
                {/*{ this.state.step === 1 && <SignupLaTua readLaTua={this.readLaTua} />}*/}
                { this.state.step === 0 && <SignupForm profile={this.state.profile} hasLaTua={this.state.hasLaTua} />}
            </div>
        );
    }
}