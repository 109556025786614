import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import MainTemplate from './components/MainComponent/Template/MainTemplate';
import TotemTemplate from './components/MainComponent/Template/TotemTemplate';
import { authenticationService } from "./services/basis/AuthenticationService"
import { CartContext } from "./cart-context"
// import Fullscreen from "react-full-screen";

// custom router
import { Router } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'

// redux
import * as actionCreators from "./redux/store/actions/index"
import { connect } from "react-redux"

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faPlus, faClipboardList,
  faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
library.add(fab, faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faClipboardList,
  faPlus, faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt)

// custom history
export const history = createBrowserHistory({ basename: "/app" });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isFull: false,
    };
  }

  // componentDidMount() {
  //   if (this.props.user) {
  //     authenticationService.isLogged()
  //       .then(response => {
  //       }).catch((error) => {
  //         this.props.logout();
  //       })
  //   }
  // }

  // goFull = () => {
  //   console.debug('going full screen');
  //   this.setState({ isFull: true });
  // }

  render() {
    return (
      <>
        {/* <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({ isFull })}> */}
        <CartContext.Provider value={this.state}>
          <Router history={history}>
            <ScrollToTop>
              <Switch>
                <Route exact path='/' component={MainTemplate} />
                <Route exact path='/stores' component={MainTemplate} />
                <Route exact path='/card' component={MainTemplate} />
                <Route exact path='/tac' component={MainTemplate} />
                <Route exact path='/privacy' component={MainTemplate} />
                <Route exact path='/generals' component={MainTemplate} />
                <Route exact path='/support' component={MainTemplate} />
                <Route exact path='/cookies' component={MainTemplate} />
                <Route exact path='/conditions' component={MainTemplate} />
                <Route exact path='/login' component={MainTemplate} />
                <Route exact path='/signup' component={MainTemplate} />
                <Route exact path='/profile' component={MainTemplate} />
                <Route exact path='/leaflets' component={MainTemplate} />
                <Route exact path='/leaflet' component={MainTemplate} />
                <Route exact path='/reset' component={MainTemplate} />
                <Route exact path='/tutorial-account-delete' component={MainTemplate} />

                {/* 
                  <Route exact path='/totem' render={() => <TotemTemplate goFull={this.goFull} isFull={this.state.isFull} />} />
                  <Route exact path='/totem/:label' render={() => <TotemTemplate goFull={this.goFull} isFull={this.state.isFull} />} />
                  */}
                <Route exact path='/totem' component={TotemTemplate} />
                <Route exact path='/totem/upgrade' component={TotemTemplate} />
                <Route exact path='/totem/:label' component={TotemTemplate} />
              </Switch>
            </ScrollToTop>
          </Router>
        </CartContext.Provider>
        {/* </Fullscreen> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
