import React from "react";
import Navbar from "./SbNavbar";
import { Translation } from 'react-i18next';

class Header extends React.Component {
    render() {
        const { user, logout } = this.props;
        return (
            <div className="header">
                {
                    <div className="d-block d-sm-block d-md-none">
                        <Translation>
                            {t =>
                                <Navbar user={user} logout={logout} />
                            }
                        </Translation>
                    </div>
                }
            </div>
        );
    }
}

export default Header;
