import React from "react"
import { Link } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <CookieConsent
                    location="bottom"
                    buttonText="OK"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}>
                    Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito.
                    Continuando a navigare nel sito si accetta la nostra politica
                    cookie. <b><Link to="/cookies" style={{ color: "rgb(255, 212, 45)" }}>Leggi di più</Link></b>
                </CookieConsent>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <Link to="/generals">Condizioni Generali del Sito</Link><br />
                            <Link to="/tac">Regolamento della "I AM Despar Card"</Link><br />
                            <Link to="/privacy">Informativa Privacy</Link><br />
                            <Link to="/cookies">Cookies Policy</Link>
                        </div>
                        <div className="col-md-3 text-center mb-3">
                            <span>Centro 3 A S.p.A.</span><br />
                            <span>Strada San Bartolomeo n.276/A <br />
                                Fraz. Quarto Inferiore, 14030 Asti (AT)
                            </span><br />
                            <span>Capitale sociale: 6.000.000 &euro;</span><br />
                            <span>Cod. Fisc. 02457360044 - P.IVA 01206220053</span><br /><br />
                            <span>Made by RetAPPs © 2019-2024 All rights reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
