export const STORE_ITEM = "STORE_ITEM"
export const CURRENT_CART = "CURRENT_CART"
export const AVAILABILITY = "AVAILABILITY"
export const ABORT_CART = "ABORT_CART"
export const MOVE_CART = "MOVE_CART"
export const FREEZE_CART = "FREEZE_CART"
export const OPEN_CART = "OPEN_CART"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const USER_UPDATE = "USER_UPDATE"
export const USER_DELETE = "USER_DELETE"
export const IS_LOGGED = "IS_LOGGED"
export const CARD_DISABLE = "CARD_DISABLE"
export const FAVORITES = "FAVORITES"
export const ADD_FAVORITE = "ADD_FAVORITE"
export const DELETE_FAVORITE = "DELETE_FAVORITE"
export const SLISTS = "SLISTS"
export const COORDINATE = "COORDINATE"
export const ALERT = "ALERT"
export const CLOSE_ALERT = "CLOSE_ALERT"