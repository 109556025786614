import React from "react";
import Map from "../../../components/UI/Maps/Map";
import StoreInfo from "./StoreInfo/StoreInfo";
import leafletsByStore from "./Service"
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom"

export default class Store extends React.Component {

    state = {
        leaflets: []
    }

    componentDidMount() {
        leafletsByStore(this.props.selectedStore.id)
            .then(response => {
                this.setState({
                    leaflets: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectedStore.id !== this.props.selectedStore.id) {
            leafletsByStore(nextProps.selectedStore.id)
                .then(response => {
                    this.setState({
                        leaflets: response.data,
                    })
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    render() {
        const { selectedStore } = this.props;
        const { leaflets } = this.state;
        if (selectedStore) {
            return (
                <Translation>
                    {t =>
                        <>
                            <div className="text-center mb-3">
                                <h5 className="">{selectedStore.name}</h5>
                                <div>
                                    {selectedStore.address && selectedStore.address.address} <br />
                                    {selectedStore.address && selectedStore.address.zip} - {selectedStore.address && selectedStore.address.city} ({selectedStore.address && selectedStore.address.county})
                                </div>
                            </div>

                            {selectedStore.address && selectedStore.address.latitude && selectedStore.address.longitude &&
                                <div className="mb-3">
                                    <Map lat={selectedStore.address.latitude} lng={selectedStore.address.longitude} zoom={18} />
                                </div>
                            }

                            <StoreInfo selectedStore={selectedStore} />

                            {leaflets.length > 0 &&
                                <>
                                    <h5 className="mb-3">{t("leaflets")}</h5>
                                    <div className="row">
                                        {leaflets.map(item => (
                                            <div key={item.id} className="col-sm-6">
                                                <Link to={{ pathname: "/leaflet", state: { leaflet: item } }}>
                                                    <div className="mb-3" key={item.id}>
                                                        <h6>{item.title}</h6>
                                                        <div className="leaflet-image">
                                                            <img src={item.coverImage} alt=""></img>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            }
                        </>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}



