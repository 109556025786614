import React from "react"
import useForm from 'react-hook-form'
import Card from "../../images/card-fidelity-transparent.png"
import { Translation } from 'react-i18next';
import { format, parseISO } from "date-fns";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import Accepts from "../../components/Accepts/Accepts";
import PasswordChange from "./PasswordChange/PasswordChange";
import ProfileAddress from "../../components/ProfileAddress/ProfileAddress";
import ProfilePreferences from "../../components/ProfilePreferences/ProfilePreferences";
import { history } from "../../App"
import Popup from "../../components/UI/Popup/Popup";

function Profile(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.updateUser(data);
    }

    return (
        <Translation>
            {t =>
                <>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                        <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                        <h4>{t('signup.title')}</h4>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="firstName">{t('firstName')}*</label>
                                <input type="text" className="form-control" id="firstName" name="firstName"
                                    defaultValue={props.user && props.user.firstName}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                    aria-describedby="firstNameHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.firstName && errors.firstName.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="lastName">{t('lastName')}*</label>
                                <input type="text" className="form-control" id="lastName" name="lastName"
                                    defaultValue={props.user && props.user.lastName}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                    aria-describedby="lastNameHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.lastName && errors.lastName.message}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="birthDate">{t('birthDate')}*</label>
                                <input type="date" className="form-control" id="birthDate" name="birthDate"
                                    defaultValue={props.user && props.user.birthDate && format(parseISO(props.user.birthDate), "yyyy-MM-dd")}
                                    ref={register({
                                        required: "Campo obbligatorio",
                                        pattern: {
                                            value: /^\d{4}-\d{2}-\d{2}$/i,
                                            message: "Data non valida"
                                        }
                                    })}
                                    aria-describedby="birthDateHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.birthDate && errors.birthDate.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="gender">{t('gender')}*</label>
                                <select className="form-control" id="gender" name="gender"
                                    defaultValue={props.user && props.user.gender}
                                    ref={register({ required: "Campo obbligatorio" })}>
                                    <option value="MALE">{t("male")}</option>
                                    <option value="FEMALE">{t("female")}</option>
                                </select>
                                <div className="form-control-invalid">
                                    {errors.gender && errors.gender.message}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <h4>{t('signup.account')}</h4>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="email">{t('email')}{history.location.pathname.indexOf("/totem") > -1 ? "" : "*"}</label>
                                <input type="email" className="form-control" id="email" name="email" disabled
                                    defaultValue={props.user && props.user.email}
                                    ref={
                                        history.location.pathname.indexOf("/totem") > -1
                                            ?
                                            register({
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Indirizzo email non valido"
                                                }
                                            })
                                            :
                                            register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Indirizzo email non valido"
                                                }
                                            })
                                    }
                                    aria-describedby="emailHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.email && errors.email.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="mobilePhone">{t('mobilePhone')}</label>
                                <input type="text" className="form-control" id="mobilePhone" name="mobilePhone"
                                    defaultValue={props.user && props.user.mobilePhone}
                                    ref={register({
                                        pattern: {
                                            value: /^\d{10}$/i,
                                            message: "Numero di cellulare non valido"
                                        }
                                    })}
                                    aria-describedby="mobilePhoneHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.mobilePhone && errors.mobilePhone.message}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <h4>{t('signup.address')}</h4>

                        <ProfileAddress user={props.user} errors={errors} register={register} />

                        <hr />

                        <h4>{t('profile.preferences')}</h4>

                        <ProfilePreferences user={props.user} errors={errors} register={register} watch={watch} />

                        <hr />

                        <h4>{t('signup.card')}</h4>

                        <div className="form-row">
                            <div className="col-md-3">
                                <img src={Card} className="img-fluid" alt="Carta 3A" />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="cardCode">{t('cardCode')}*</label>
                                <input type="text" className="form-control" id="cardCode" name="cardCode" disabled
                                    defaultValue={props.user && props.user.cards && props.user.cards[0] && props.user.cards[0].code}
                                    ref={register({
                                        required: "Campo obbligatorio",
                                        pattern: {
                                            value: /^\d{13}$/i,
                                            message: "Numero carta fedeltà non valido"
                                        }
                                    })}
                                    aria-describedby="cardCodeHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.cardCode && errors.cardCode.message}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <h4>{t('signup.privacy')}</h4>

                        <Accepts errors={errors} register={register} user={props.user} handleSubmit={handleSubmit} disabled={true} />

                        <div className="row">
                            <div className="col-sm-12 mt-3 mb-3">
                                <button className="btn btn-success btn-lg btn-block" type="submit">
                                    {t("save")}
                                </button>
                            </div>
                        </div>
                    </form>

                    <hr />

                    <h4>{t('signup.passwordChange')}</h4>

                    <PasswordChange />

                    <hr />

                    <h4>{t('signup.profileDelete')}</h4>

                    <Popup
                        action={() => props.user && props.deleteUser(props.user.id)}
                        title={t("signup.profileDelete")}
                        button={t("delete")}
                        content={t("signup.profileDelete.question")}
                        style={{ backgroundColor: "rgb(225, 37, 27)" }}
                        trigger={
                            <div className="row">
                                <div className="col-sm-12 mt-3 mb-3">
                                    <button className="btn btn-danger btn-lg btn-block" type="button">
                                        {t("delete")}
                                    </button>
                                </div>
                            </div>
                        }
                    />
                </>
            }
        </Translation>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        isLogged: () => dispatch(actionCreators.isLogged()),
        updateUser: (data) => dispatch(actionCreators.updateUser(data)),
        deleteUser: (id) => dispatch(actionCreators.deleteUser(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);