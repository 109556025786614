import CustomError from "./customError";

export function checkResponse(response) {
    if (response.responseCode !== 0) {
        throw new CustomError(response.responseCode, errorMessage(response));
    }

    return response;
}

function errorMessage(response) {
    switch (response.responseCode) {
        case 2:
            return "Utente non trovato"

        case 4:
            return "Username non valido"

        case 13:
            return "La password deve essere lunga almeno 8 caratteri e contenere almeno tre tra: lettere minuscole, maiuscole, numeri e simboli"

        case 21:
            return "Profilo non trovato"

        case 26:
            return "Un utente con la mail indicata risulta già registrato"

        case 28:
            return "Devi essere maggiorenne per poterti registrare al servizio"

        case 29:
            return "La carta inserita risulta associata ad un altro utente"

        case 30:
            return "Numero di telefono non valido"

        case 34:
            return "Vecchia password errata"

        case 35:
            return "Codice carta non valido"

        case 4000:
            return "Errore generico"

        case 4004:
            return "Carrello non trovato"

        case 4029:
            return "Hai superato il limite di orario per prenotare l'ordine"

        case 4030:
            return "Prodotto sconosciuto per lo store indicato"

        case 4031:
            return "Prodotto non valido"

        case 4034:
            return "Massima quantità acquistabile raggiunta"

        case 4205:
            return "Stato dell'ordine errato"

        default:
            return response.errorMessage;
    }
}