import React from 'react'
import first from '../../images/homepage.png'
import second from '../../images/elimina profilo.png'
import third from '../../images/popup.png'
import './delete.styles.scss'

const Delete = () => {
  return (
    <div className='delete'>
        <label className='title'>COME CANCELLARE IL PROFILO SU APP I AM DESPAR</label>
        <label className='subtitle'>Per poter cancellare il proprio profilo su App I AM DESPAR basta toccare l’icona “Profilo”, scorrere in basso e toccare la voce “Cancella profilo” e confermare quanto viene richiesto.</label>
        <div className='image-container'>
            <img src={first} />
            <img src={second} />
            <img src={third} />
        </div>
    </div>
  )
}

export default Delete