const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 5,
    // DEFAULT_STORE_ID : "200071",
    // DEFAULT_STORE_DESCRIPTION : "Coop Trentino - Ravina",
    // DEFAULT_STATE: "Trentino Alto Adige",
    // DEFAULT_STATE_ID: "TRE",
    // SHOW_STATE_DROPDOWN : false,
    // SHOW_CART_LISTS: true,
    PLATFORM_ANDROID: "Android",
    PLATFORM_IOS: "iOS",
}

export default config;