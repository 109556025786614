import React from "react"
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import useForm from 'react-hook-form'
import * as actionCreators from "../../redux/store/actions/index"
import { connect } from "react-redux"
import { Translation } from "react-i18next";
import { Link } from "react-router-dom"

function Login(props) {
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.login(data);
    }

    return (
        <Translation>
            {t =>
                <div className="container-fluid h-100">
                    <Row>
                        <div className="container-fluid mt-3">
                            <Row>
                                <Col sm={12} md={6}>
                                    <h3 className="mb-4">{t("login")}</h3>
                                    <h5>{t("login.subitle")}</h5>
                                    <div className="mb-2">{t("login.paragraph")}</div>
                                    <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                        className={`needs-validation ${errors ? "had-validation" : ""}`}>
                                        <Row>
                                            <Col md={12} className="form-group">
                                                <label htmlFor="username">{t('username')}*</label>
                                                <input type="text" className="form-control mb-3" id="username" name="username"
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: "Indirizzo email non valido"
                                                        }
                                                    })}
                                                    aria-describedby="usernameHelp" placeholder="" />
                                                <div className="form-control-invalid">
                                                    {errors.username && errors.username.message}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="form-group">
                                                <label htmlFor="password">{t('password')}*</label>
                                                <input type="password" className="form-control mb-3" id="password" name="password"
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                        minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                                    })}
                                                    aria-describedby="passwordHelp" placeholder="" />
                                                <div className="form-control-invalid">
                                                    {errors.password && errors.password.message}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <button type="submit" className="btn-primary btn">
                                                    <span>{t("access")}</span>
                                                </button>
                                                <Link className="ml-3" to="/reset">
                                                    {t("passwordReset")}?
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                                <Col sm={12} md={6} className="sign-up-link">
                                    <h3 className="mb-4">{t("new_account")}</h3>
                                    <h5>{t("login_new_account.subitle")}</h5>
                                    <div className="text-md-left mt-4">
                                        <Link className="btn-primary btn" to="/signup">
                                            {t("sign_in")}
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </div>
            }
        </Translation>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(actionCreators.login(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
