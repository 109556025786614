import React, { useEffect, useRef, useState } from "react"
import useForm from 'react-hook-form'
import { Translation } from 'react-i18next';
import { format, parseISO } from "date-fns";
import swal from "../../../utils/swal"
import { authenticationService } from "../../../services/basis/AuthenticationService"
import Card from "../../../images/card-fidelity-transparent.png"
import { withRouter } from "react-router";
import Accepts from "../../../components/Accepts/Accepts";
import ProfileAddress from "../../../components/ProfileAddress/ProfileAddress";
import ProfilePreferences from "../../../components/ProfilePreferences/ProfilePreferences";

function SignupForm(props) {
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        mode: 'onChange',
    });

    const origin = props.location.state ? props.location.state.origin : "web"
    const cardInput = useRef(null);

    useEffect(() => {
        if (props.hasLaTua) {
            cardInput.current.focus();
        }
    }, [props.hasLaTua]);

    const onSubmit = data => {
        authenticationService.signup(data)
            .then(response => {
                // console.log(response)
                swal.success("Registrazione effettuata",
                    "A breve riceverai una email per completare il processo di registrazione.");
            }).catch((error) => {
                if (error.errorCode === 13) {
                    swal.error("Password non valida", error.message);
                } else {
                    swal.error(error.message);
                }
            })
    }

    // console.log(watch())
    // console.log(watch('errors'))

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                    <input type="hidden" id="attrib1" name="attrib1"
                        ref={register} defaultValue={props.profile.attrib1} />

                    <input type="hidden" id="attrib2" name="attrib2"
                        ref={register} defaultValue={origin} />

                    <div className="form-fields-box">
                        <p className="text-center"><b>CAMPI OBBLIGATORI</b></p>

                        <h4>{t('signup.title')}</h4>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="firstName">{t('firstName')}*</label>
                                <input type="text" className="form-control form-control-lg input-required" id="firstName" name="firstName"
                                    defaultValue={props.profile.firstName}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                    aria-describedby="firstNameHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.firstName && errors.firstName.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="lastName">{t('lastName')}*</label>
                                <input type="text" className="form-control form-control-lg input-required" id="lastName" name="lastName"
                                    defaultValue={props.profile.lastName}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                    aria-describedby="lastNameHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.lastName && errors.lastName.message}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="birthDate">{t('birthDate')}*</label>
                                <input type="date" className="form-control form-control-lg input-required" id="birthDate" name="birthDate"
                                    defaultValue={props.profile && props.profile.birthDate && format(parseISO(props.profile.birthDate), "yyyy-MM-dd")}
                                    ref={register({
                                        required: "Campo obbligatorio",
                                        pattern: {
                                            value: /^\d{4}-\d{2}-\d{2}$/i,
                                            message: "Data non valida"
                                        }
                                    })}
                                    aria-describedby="birthDateHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.birthDate && errors.birthDate.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="gender">{t('gender')}*</label>
                                <select className="form-control form-control-lg input-required" id="gender" name="gender"
                                    defaultValue={props.profile.gender}
                                    ref={register({ required: "Campo obbligatorio" })}>
                                    <option value="MALE">{t("male")}</option>
                                    <option value="FEMALE">{t("female")}</option>
                                </select>
                                <div className="form-control-invalid">
                                    {errors.gender && errors.gender.message}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <h4>{t('signup.account')}</h4>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="email">{t('email')}*</label>
                                <input type="email" className="form-control form-control-lg input-required" id="email" name="email"
                                    defaultValue={props.profile.email}
                                    ref={register({
                                        required: "Campo obbligatorio",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Indirizzo email non valido"
                                        }
                                    })}
                                    aria-describedby="emailHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.email && errors.email.message}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="password">{t('password')}*</label>
                                <input type="password" className="form-control form-control-lg input-required" id="password" name="password"
                                    ref={register({
                                        required: "Campo obbligatorio",
                                        minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                    })}
                                    aria-describedby="passwordHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.password && errors.password.message}
                                </div>
                                <small id="passwordHelp" className="form-text text-muted">La password deve essere lunga almeno 8 caratteri e contenere
                                    almeno tre tra: lettere minuscole, maiuscole, numeri e simboli</small>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="passwordConfirm">{t('passwordConfirm')}*</label>
                                <input type="password" className="form-control form-control-lg input-required" id="passwordConfirm" name="passwordConfirm"
                                    ref={register({
                                        required: "Campo obbligatorio",
                                        validate: {
                                            passwordMatch: value => value === watch('password')
                                        }
                                    })}
                                    aria-describedby="passwordConfirmHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.passwordConfirm && errors.passwordConfirm.message}
                                    {errors.passwordConfirm && errors.passwordConfirm.type === "passwordMatch" && (
                                        <span>Le password non coincidono</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <h4>{t('signup.address')}</h4>

                        <ProfileAddress user={props.profile && props.profile} errors={errors} register={register} watch={watch} />

                        <hr />

                        <h4>{t('signup.card')}</h4>

                        <div className="form-row">
                            <div className="col-md-3">
                                <img src={Card} className="img-fluid" alt="Carta 3A" />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="cardCode">{t('cardCode')}</label>
                                <input type="number" className="form-control form-control-lg" id="cardCode" name="cardCode"
                                    value={props.profile.cardCode}
                                    ref={(e) => {
                                        register(e, {
                                            pattern: {
                                                value: /^043[0-1]00\d{7}$/i,
                                                message: "Numero carta fedeltà non valido"
                                            }
                                        })
                                        cardInput.current = e // you can still assign to your own ref
                                    }}
                                    maxLength={13}
                                    aria-describedby="cardCodeHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.cardCode && errors.cardCode.message}
                                </div>
                                <small id="cardCodeHelp" className="form-text text-muted">Inserisci qui il numero della tua carta, oppure lascialo vuoto per riceverne una nuova digitale</small>
                            </div>
                        </div>

                    </div>

                    <p>&nbsp;</p>

                    <div className="form-fields-box">
                        <p className="text-center"><b>CAMPI OPZIONALI</b><br />
                            Per favore compila i campi qui sotto, ci aiuterai a studiare le migliori offerte per te.<br />
                            L'uso di queste informazioni sarà in accordo ai consensi concessi nell'area Privacy.</p>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="mobilePhone">{t('mobilePhone')}</label>
                                <input type="text" className="form-control form-control-lg" id="mobilePhone" name="mobilePhone"
                                    defaultValue={props.profile.mobilePhone}
                                    ref={register({
                                        pattern: {
                                            value: /^\d{9,10}$/i,
                                            message: "Numero di cellulare non valido"
                                        }
                                    })}
                                    aria-describedby="mobilePhoneHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.mobilePhone && errors.mobilePhone.message}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <h4>{t('profile.preferences')}</h4>

                        <ProfilePreferences errors={errors} register={register} watch={watch} />

                    </div>

                    <hr />

                    <h4>{t('signup.privacy')}</h4>

                    <Accepts errors={errors} register={register} mobilePhone={watch('mobilePhone')} />

                    <div className="row">
                        <div className="col-sm-12 mt-3 mb-3">
                            <button className="btn btn-success btn-lg btn-block" type="submit">
                                {t("save")}
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Translation>
    );
}

export default withRouter(SignupForm);