import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import axios from 'axios';

export default function preferences() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/preferences`)
            .then(handleResponse)
            .then(checkResponse)
    );
}