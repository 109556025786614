import React from "react";
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";

export default (props) => (
    <Translation>
        {t =>
            <Popup trigger={props.trigger} modal>
                {close => (
                    <div className="popup">
                        <div className="close" onClick={close}>
                            &times;
                        </div>
                        <div className="header">{props.title}</div>
                        <div className="content text-center">
                            {props.content}
                        </div>
                        <div className="actions">
                            <button className="button abort outline" onClick={() => { close(); }}>
                                {t("cancel")}
                            </button>
                            <button className="button outline" style={{ ...props.style }} onClick={() => {
                                props.action();
                                close();
                            }}>
                                {props.button}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        }
    </Translation>
);