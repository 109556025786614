import React from 'react'

function Support() {
    return (
        <>
            <h3>Supporto</h3>
            
            <p className="mt-3">Per assistenza e supporto contattare <a href="mailto:support@retapps.com">support@retapps.com</a></p>
        </>
    );
}

export default Support
