import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import axios from 'axios';

export const storesService = {
    stores, storesEcommerce, states, counties, storesByDelivery, storesCoordinate, storesCoordinateEcommerce
};

function stores(page, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores?s=${config.maxSize}&p=${page}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function storesEcommerce(page) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function storesCoordinate(page, lat, lon, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores?s=${config.maxSize}&p=${page}&lat=${lat}&lon=${lon}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function storesCoordinateEcommerce(page, lat, lon) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}&lat=${lat}&lon=${lon}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function states() {
    return (
        axios.get(`${config.baseUrl}/cli/stores/states`)
            .then(handleResponse)
        //.then(checkResponse)
    );
}

function counties() {
    return (
        axios.get(`${config.baseUrl}/cli/stores/counties`)
            .then(handleResponse)
        //.then(checkResponse)
    );
}

function storesByDelivery(state, deliveryMode) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce/delivery?delivery=${deliveryMode}&state=${state}`)
            .then(handleResponse)
        // .then(checkResponse)
    );
}