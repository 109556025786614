import React from "react"
import { Link } from 'react-router-dom'

export default class TotemFooter extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Link to="/totem/tac">Regolamento della "I AM Despar Card"</Link><br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}