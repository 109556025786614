import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import { Translation } from 'react-i18next';
import Logo from "../../../images/logo_despar.png"
import Card from "../../../images/card-fidelity-transparent.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenuItem from './SideMenuItem';

class SideMenu extends Component {
    render() {
        return (
            <Translation>
                {t =>
                    <div>
                        <div className="logo">
                            <Link to="/">
                                <img src={Logo} alt='logo' className="img-fluid" />
                            </Link>
                            <div className="text-right text-primary logo-text">Nord Ovest</div>
                        </div>

                        <div className="text-center row mb-4 mt-4">
                            <div className="col-md-10 offset-md-1">
                                <img src={Card} alt='card' className="img-fluid" />
                            </div>
                        </div>

                        <div className="menu-item active">
                            <FontAwesomeIcon icon="bars"></FontAwesomeIcon>&nbsp;&nbsp;&nbsp;Menu
                        </div>

                        {this.props.user && <SideMenuItem label={t("profile")} to="/profile" />}
                        {this.props.user && <SideMenuItem label={t("card")} to="/card" />}
                        <SideMenuItem label={t("stores")} to="/stores" />
                        <SideMenuItem label={t("leaflets")} to="/leaflets" />
                        {this.props.user && <SideMenuItem label={t("logout")} to="#" onClick={() => this.props.logout(this.props.history)} />}
                        {!this.props.user && <SideMenuItem label={t("access")} to="/login" />}
                        {!this.props.user && <SideMenuItem label={t("sign_in")} to="/signup" />}
                        <SideMenuItem label="torna al sito" href="http://www.desparsupermercati.it" />
                    </div>
                }
            </Translation>
        )
    }
}

export default withRouter(SideMenu);
