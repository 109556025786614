import React, { Component } from 'react'
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet'
import PropTypes from 'prop-types'

export default class GlobalMap extends Component {
    render() {
        const position = [this.props.lat, this.props.lng]
        return (
            <LeafletMap center={position} zoom={this.props.zoom}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {this.props.stores.map(store => {
                    if (store.address && store.address.latitude && store.address.longitude) {
                        const position = [store.address.latitude, store.address.longitude];
                        return (
                            <Marker key={position} position={position}>
                                <Popup>
                                    {store.name}
                                </Popup>
                            </Marker>
                        );
                    } else
                        return null;
                })}
            </LeafletMap>
        )
    }
}

Map.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired
}