import React from "react"
import SignaturePad from 'react-signature-canvas'
import swal from "../../utils/swal"
import { authenticationService } from "../../services/basis/AuthenticationService"
import { history } from "../../App"
import GoBackButton from "../../components/UI/Buttons/GoBackButton"

export default class Signature extends React.Component {

    state = {
        isDisabled: false
    }

    sigPad = {}

    clear = () => {
        this.sigPad.clear()
    }

    signup = () => {
        if (!this.sigPad.isEmpty()) {
            this.setState({ isDisabled: true });
            const data = this.props.formData;
            data.signature = (this.sigPad.toDataURL("image/svg+xml").split(","))[1]
            authenticationService.signup(data)
                .then(response => {
                    swal.success("Registrazione effettuata");
                    setTimeout(function () {
                        history.push("/totem");
                    }, 3000);
                }).catch((error) => {
                    swal.error(error.message);
                })
        } else {
            swal.error("Devi firmare per poter temirnare la registrazione");
        }
    }

    render() {
        return (
            <>
                <GoBackButton goBack={() => this.props.backToForm()}>
                    Indietro
                </GoBackButton>
                <h3 className="text-center">Firma e registrati</h3>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="btn-primary btn-sm" onClick={this.clear}>Cancella</button>
                </div>
                {/* <button onClick={this.clear} className="pr-3 pt-2 close outline" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> */}
                <div className="signature">
                    <div className="sigContainer">
                        <SignaturePad canvasProps={{ className: "sigPad" }}
                            ref={(ref) => { this.sigPad = ref }} />
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 mb-3">
                            <button className="btn btn-success btn-lg btn-block" onClick={this.signup} disabled={this.state.isDisabled}>
                                <h2 style={{color: "unset"}}>Registrati</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );

    }
}

