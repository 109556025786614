import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import banners from "./Service"

export default class HomeCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            banners: []
        };
    }

    componentDidMount() {
        banners().then(response => {
            this.setState({
                isLoaded: true,
                banners: response.data
            })
        }).catch(error => {console.log(error);});
    }

    render() {
        const { isLoaded, banners } = this.state;
        return (isLoaded ?
            <Carousel className="Carousel" interval={10000} >
                {banners.map((item, index) => (
                    <Carousel.Item key={item.id}>
                        <img
                            className="d-block w-100"
                            src={item.imageUrl}
                            alt=""
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
            : null
        );
    }
}