import React, { Component } from 'react'
import FidelityCard from '../../components/MainComponent/Cards/FidelityCard';
import { format, parseISO } from "date-fns";
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import Popup from '../../components/UI/Popup/Popup';

export class Card extends Component {
    // componentDidMount() {
    //     this.props.isLogged();
    // }

    render() {
        const card = this.props.user && this.props.user.cards ? this.props.user.cards[0] : null;
        if (card) {
            return (
                <Translation>
                    {t =>
                        <>
                            <div className="row container-fluid">
                                <h3>{t('card.title')}</h3>
                            </div>
                            <div className="row container-fluid" style={{ minHeight: "500px" }}>
                                <div className="col-sm-12 col-md-8">
                                    <FidelityCard
                                        points={card.points}
                                        code={card.code}
                                        enabled={card.enabled}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="mt-5">
                                        <h5>{t("description")}:

                                        <span className="ml-1">
                                                <b>{card.description}</b>
                                            </span>
                                        </h5>
                                        <h5 className="mt-3">{t("code")}:
                                        <span className="ml-1">
                                                <b>{card.code}</b>
                                            </span>
                                        </h5>
                                        <h5 className="mt-3">{t("points")}:
                                        <span className="ml-1">
                                                <b>{card.points}</b>
                                            </span>
                                        </h5>
                                        <h5 className="mt-3">{t("created")}:
                                        <span className="ml-1">
                                                <b>{card.created && format(parseISO(card.created), "dd/MM/yyyy")}</b>
                                            </span>
                                        </h5>
                                        <h5 className="mt-3">{t("enabled")}:
                                        <span className="ml-1">
                                                <b>{`${card.enabled ? "Si" : "No"}`}</b>
                                            </span>
                                        </h5>
                                        {card.enabled &&
                                            <Popup
                                                action={() => this.props.cardDisable(card.id)}
                                                title={t("card.disable.title")}
                                                button={t("card.disable")}
                                                content={t("card.disable.question")}
                                                style={{ backgroundColor: "rgb(225, 37, 27)" }}
                                                trigger={
                                                    <button className={`mt-2 btn btn-danger`}>
                                                        {t("card.disable")}
                                                    </button>
                                                }
                                            />
                                        }
                                        {!card.enabled &&
                                            <h4 className="mt-3 text-danger">Carta disabilitata!</h4>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Translation>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        isLogged: () => dispatch(actionCreators.isLogged()),
        cardDisable: (id) => dispatch(actionCreators.cardDisable(id)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Card);
