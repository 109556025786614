import React from "react";
import { Route, Switch, withRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import TotemHeader from "../Header/TotemHeader";

import TotemHome from '../../../features/Home/TotemHome';
import TotemSignup from "../../../features/Signup/TotemSignup"
import Card from '../../../features/Card/Card';
import Tac from '../../../pages/Tac';
import Privacy from '../../../pages/Privacy';
import Signature from "../../../features/Signature/Signature";
import PasswordReset from "../../../features/PasswordReset/PasswordReset";
import IdleTimer from 'react-idle-timer'
import { connect } from "react-redux"
import qs from "query-string";
import { history } from "../../../App"
import * as actionCreators from "../../../redux/store/actions/index"
import TotemLogin from "../../../features/Login/TotemLogin";
import TotemProfile from "../../../features/Profile/TotemProfile";
import GeneralConditions from "../../../pages/GeneralConditions";
import Upgrade from "../../../features/Login/Upgrade";
import TotemLoginDigi from "../../../features/Login/TotemLoginDigi";

class TotemTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
    }

    componentDidMount() {
        const storeId = qs.parse(history.location.search).storeId;
        localStorage.setItem("storeId", storeId);
    }

    onActive = (e) => {
        console.log('user is active', e)
        console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    onIdle = (e) => {
        console.log('user is idle', e)
        console.log('last active', this.idleTimer.getLastActiveTime())
        if (this.props.user) {
            this.props.logout();
        } else {
            history.push("/totem")
        }
    }

    render() {
        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={60000} />
                {this.props.location.pathname !== "/totem/DIGI" &&
                    <TotemHeader />
                }
                <Container>
                    <Switch>
                        <Route exact path='/totem' component={TotemHome} />
                        <Route exact path='/totem/card' component={Card} />
                        <Route exact path='/totem/tac' component={Tac} />
                        <Route exact path='/totem/privacy' component={Privacy} />
                        <Route exact path='/totem/generals' component={GeneralConditions} />
                        <Route exact path='/totem/login' component={TotemLogin} />
                        <Route exact path='/totem/signup' component={TotemSignup} />
                        <Route exact path='/totem/upgrade' component={Upgrade} />
                        <Route exact path='/totem/profile' component={TotemProfile} />
                        <Route exact path='/totem/signature' component={Signature} />
                        <Route exact path='/totem/reset' component={PasswordReset} />
                        <Route exact path='/totem/DIGI' component={TotemLoginDigi} />
                    </Switch>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TotemTemplate));