import { handleResponse } from '../../../utils/handleResponse';
import { checkResponse } from '../../../utils/checkResponse';
import config from '../../../config/config';
import axios from 'axios';

export default function leafletsByStore(id) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/leaflets/store/${id}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}