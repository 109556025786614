import React, { useState, useEffect, useRef } from "react"
import useForm from 'react-hook-form'
import Card from "../../../images/card-fidelity-transparent.png"
import { Translation } from 'react-i18next';
import { parseISO } from "date-fns";
import Accepts from "../../../components/Accepts/Accepts";
import ProfileAddress from "../../../components/ProfileAddress/ProfileAddress";
import ProfilePreferences from "../../../components/ProfilePreferences/ProfilePreferences";

import Datepicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import it from "date-fns/esm/locale/it";
registerLocale("it", it)
setDefaultLocale(it);

export default function SignupForm(props) {

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onChange',
    });

    // eslint-disable-next-line
    const [birthDate, setBirthdate] = useState(props.profile && props.profile.birthDate && parseISO(props.profile.birthDate) || props.formData.birthDate);
    const cardInput = useRef(null);

    const handleChange = date => {
        setValue("birthDate", date);
        setBirthdate(date);
        if (date) {
            errors.birthDate = {};
            errors.birthDate.message = "";
        } else {
            errors.birthDate = {};
            errors.birthDate.message = "Campo obbligatorio";
        }
    }

    useEffect(() => {
        if (props.hasLaTua) {
            cardInput.current.focus();
        }
        register(
            { name: "birthDate" },
            { required: "Campo obbligatorio" },
        )

        setValue("birthDate", birthDate);
    }, [register, birthDate, setValue, props.hasLaTua])

    const onSubmit = data => {
        props.signature(data);
    }

    const storeId = localStorage.getItem("storeId") !== "undefined" ? localStorage.getItem("storeId") : "";

    // console.log(watch())
    // console.log(watch("errors"))

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                    <input type="hidden" id="attrib1" name="attrib1"
                        ref={register} defaultValue={props.profile.attrib1} />

                    <input type="hidden" id="attrib2" name="attrib2"
                        ref={register} defaultValue="totem" />

                    <input type="hidden" id="attrib3" name="attrib3"
                        ref={register} defaultValue={storeId} />

                    <input type="hidden" id="terminalType" name="terminalType"
                        ref={register} defaultValue="totem" />

                    <div className="form-fields-box mb-3">
                        <h4>{t('signup.card')}</h4>

                        <div className="form-row">
                            <div className="col-md-3">
                                <img src={Card} className="img-fluid" alt="Carta 3A" />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="cardCode">{t('cardCode')}</label>
                                <input type="text" className="form-control form-control-lg" id="cardCode" name="cardCode"
                                    value={props.profile.cardCode}
                                    ref={(e) => {
                                        register(e, {
                                            pattern: {
                                                value: /^043[0-1]00\d{7}$/i,
                                                message: "Numero carta fedeltà non valido"
                                            }
                                        })
                                        cardInput.current = e // you can still assign to your own ref
                                    }}
                                    maxLength={13}
                                    aria-describedby="cardCodeHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.cardCode && errors.cardCode.message}
                                </div>
                                <small id="cardCodeHelp" className="form-text text-muted">Inserisci qui il numero della tua carta, oppure lascialo vuoto per riceverne una nuova digitale</small>
                            </div>
                        </div>
                        <hr />
                        <p className="text-center"><b>CAMPI OBBLIGATORI</b></p>

                        <h4>{t('signup.title')}</h4>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="firstName">{t('firstName')}*</label>
                                <input type="email" className="form-control form-control-lg input-required" id="firstName" name="firstName"
                                    defaultValue={props.profile.firstName || props.formData.firstName}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                    aria-describedby="firstNameHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.firstName && errors.firstName.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="lastName">{t('lastName')}*</label>
                                <input type="email" className="form-control form-control-lg input-required" id="lastName" name="lastName"
                                    defaultValue={props.profile.lastName || props.formData.lastName}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                    aria-describedby="lastNameHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.lastName && errors.lastName.message}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="birthDate">{t('birthDate')}*</label><br />
                                <Datepicker
                                    isClearable
                                    autoComplete="off"
                                    selected={birthDate}
                                    onChange={handleChange}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    locale="it"
                                    showMonthDropdown
                                    showYearDropdown
                                    fixedHeight
                                    popperPlacement="bottom-start"
                                    popperClassName="smartbip-datepicker"
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                    withPortal
                                    onFocus={e => e.target.blur()}
                                    customInput={
                                        <input type="text" className="form-control form-control-lg input-required"
                                            aria-describedby="birthDateHelp"
                                            placeholder=""
                                        />
                                    }
                                />
                                <div className="form-control-invalid">
                                    {errors.birthDate && errors.birthDate.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="gender">{t('gender')}*</label>
                                <select className="form-control form-control-lg input-required" id="gender" name="gender"
                                    defaultValue={props.profile.gender || props.formData.gender}
                                    ref={register({ required: "Campo obbligatorio" })}>
                                    <option value="MALE">{t("male")}</option>
                                    <option value="FEMALE">{t("female")}</option>
                                </select>
                                <div className="form-control-invalid">
                                    {errors.gender && errors.gender.message}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <h4>{t('signup.address')}</h4>

                        <ProfileAddress user={props.profile && props.profile} formData={props.formData} errors={errors} register={register} />
                    </div>

                    <div className="form-fields-box mb-3">
                        <p className="text-center"><b>CAMPI OPZIONALI</b><br />
                            Per favore compila i campi qui sotto, ci aiuterai a studiare le migliori offerte per te.<br />
                            L'uso di queste informazioni sarà in accordo ai consensi concessi nell'area Privacy.</p>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="email">{t('email')}</label>
                                <input type="email" className="form-control form-control-lg" id="email" name="email"
                                    defaultValue={props.profile.email || props.formData.email}
                                    ref={register({
                                        required: watch('cardCode') === "" ? "Campo obbligatorio se non presente la carta" : false,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Indirizzo email non valido"
                                        }
                                    })}
                                    aria-describedby="emailHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.email && errors.email.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="mobilePhone">{t('mobilePhone')}</label>
                                <input type="tel" className="form-control form-control-lg" id="mobilePhone" name="mobilePhone"
                                    defaultValue={props.profile.mobilePhone || props.formData.mobilePhone}
                                    ref={register({
                                        pattern: {
                                            value: /^\d{9,10}$/i,
                                            message: "Numero di cellulare non valido"
                                        }
                                    })}
                                    aria-describedby="mobilePhoneHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.mobilePhone && errors.mobilePhone.message}
                                </div>
                            </div>

                        </div>

                        <hr />

                        <h4>{t('profile.preferences')}</h4>

                        <ProfilePreferences errors={errors} register={register} watch={watch} />

                    </div>

                    <h4>{t('signup.privacy')}</h4>

                    <Accepts errors={errors} register={register} isTotem={true} mobilePhone={watch('mobilePhone')} />

                    <div className="row">
                        <div className="col-sm-12 mt-3 mb-3">
                            <button className="btn btn-success btn-lg btn-block" type="submit">
                                {t("save")}
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Translation>
    );
}