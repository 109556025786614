import React from "react"
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import useForm from 'react-hook-form'
import * as actionCreators from "../../redux/store/actions/index"
import { connect } from "react-redux"
import { Translation } from "react-i18next";
import Accepts from "../../components/Accepts/Accepts";
import swal from "../../utils/swal";
import { authenticationService } from "../../services/basis/AuthenticationService";

function Upgrade(props) {
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        console.log(data)
        authenticationService.upgrade(data)
            .then(response => {
                console.log(response);
                props.logout();
                swal.success("Profilo aggiornato con successo",
                    "A breve riceverai una mail per completare l’aggiornamento e riabilitare il tuo profilo.");
            })
            .catch((error) => {
                if (error.errorCode === 13) {
                    swal.error("Password non valida");
                } else {
                    swal.error(error.message);
                }
            })
    }

    return (
        <Translation>
            {t =>
                <div className="container-fluid h-100">
                    <Row>
                        <div className="container-fluid mt-3">
                            <Row>
                                <Col md={8}>
                                    <h3 className="mb-2">Gentile Cliente</h3>
                                    <h5 className="mb-4">
                                        Specificando il tuo indirizzo email e scegliendo una password avrai modo di utilizzare l’APP I AM DESPAR e accedere ai servizi del nostro sito web.
                                    </h5>
                                    <h5 className="mb-4">
                                        Inoltre, concedendo i consensi sotto riportati, potrai ricevere coupon e altre   opportunità di risparmio a te riservate.
                                    </h5>
                                    <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                        className={`needs-validation ${errors ? "had-validation" : ""}`}>
                                        <Row>
                                            <Col md={12} className="form-group">
                                                <h5 htmlFor="username">Username(Email)*</h5>
                                                <input type="email" className="form-control form-control-lg mb-3"
                                                    id="username" name="username"
                                                    defaultValue={props.user && props.user.email}
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                            message: "Email non valido"
                                                        }
                                                    })}
                                                    aria-describedby="usernameHelp" placeholder="" />
                                                <div className="form-control-invalid">
                                                    {errors.username && errors.username.message}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="form-group">
                                                <h5 htmlFor="password">Password*</h5>
                                                <input type="password" className="form-control form-control-lg"
                                                    id="password" name="password"
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                    })}
                                                    aria-describedby="passwordHelp" placeholder=""
                                                    autoComplete="off" />
                                                <div className="form-control-invalid">
                                                    {errors.password && errors.password.message}
                                                </div>
                                                <small id="newPasswordHelp" className="form-text text-muted">
                                                    La password deve essere lunga almeno 8 caratteri e contenere
                                                    almeno tre tra: lettere minuscole, maiuscole, numeri e simboli
                                                </small>
                                            </Col>
                                        </Row>
                                        <Accepts errors={errors} register={register} user={props.user} handleSubmit={handleSubmit} disabled={true} isTotem={true} />
                                        <div className="text-center mt-3 text-md-left">
                                            <button type="submit" className="btn-primary btn">
                                                <span>PASSO 2 di 2 - AGGIORNA I TUOI DATI</span>
                                            </button>
                                            <div>
                                                <small><b>Riceverai una email per confermare il tuo indirizzo con un link per attivare l’account.</b></small>
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </div>
            }
        </Translation>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);
