import React from "react";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
import TotemFooter from "../../components/MainComponent/Footer/TotemFooter";

export default class TotemHome extends React.Component {
    render() {
        return (
            <>
                <HomeCarousel />
                <TotemFooter />
            </>
        );
    }
}
