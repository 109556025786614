import React from "react"
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import useForm from 'react-hook-form'
import * as actionCreators from "../../redux/store/actions/index"
import { connect } from "react-redux"
import { Translation } from "react-i18next";
import { Link } from "react-router-dom"
// import ReCAPTCHA from "react-google-recaptcha";

// const recaptchaRef = React.createRef();

function TotemLogin(props) {
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        // const recaptcha = recaptchaRef.current.getValue();
        // data.recaptcha = recaptcha;
        props.login(data);
    }

    return (
        <Translation>
            {t =>
                <div className="container-fluid h-100">
                    <Row>
                        <div className="container-fluid mt-3">
                            <Row>
                                <Col md={6}>
                                    <h3 className="mb-4">{t("login")}</h3>
                                    <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                        className={`needs-validation ${errors ? "had-validation" : ""}`}>
                                        <Row>
                                            <Col md={12} className="form-group">
                                                <h5 htmlFor="username">Il codice della carta I AM*</h5>
                                                <input type="number" className="form-control form-control-lg mb-3" id="username" name="username"
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                        pattern: {
                                                            value: /^043[0-1]00\d{7}$/i,
                                                            message: "Numero carta fedeltà non valido"
                                                        }
                                                    })}
                                                    aria-describedby="usernameHelp" placeholder="" />
                                                <div className="form-control-invalid">
                                                    {errors.username && errors.username.message}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="form-group">
                                                <h5 htmlFor="password">Il tuo cognome*</h5>
                                                <input type="password" className="form-control form-control-lg" id="password" name="password"
                                                    ref={register({
                                                        required: "Campo obbligatorio",
                                                    })}
                                                    aria-describedby="passwordHelp" placeholder="" autoComplete="off" />
                                                <div className="form-control-invalid">
                                                    {errors.password && errors.password.message}
                                                </div>
                                            </Col>
                                        </Row>
                                        {/*
                                        <ReCAPTCHA
                                            sitekey="6LfkksgUAAAAADFJChAOeYxKj0ZnylfOR4AVX2td"
                                            ref={recaptchaRef}
                                        />
                                         */}
                                        <div className="text-center mt-3 text-md-left">
                                            <button type="submit" className="btn-primary btn">
                                                <span>{t("access")}</span>
                                            </button>
                                            {/*
                                            <Link className="ml-3" to="/totem/reset">
                                                {t("passwordReset")}?
                                            </Link>
                                             */}
                                        </div>
                                    </Form>
                                </Col>
                                <Col md={6}>
                                    <h3 className="mb-4">{t("new_account")}</h3>
                                    <h5>{t("login_new_account.subitle")}</h5>
                                    <div className="text-center text-md-left">
                                        <Link to="/totem/signup">
                                            {t("sign_in")}
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </div>
            }
        </Translation>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(actionCreators.login(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TotemLogin);
