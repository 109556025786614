import React from 'react'
import GoBackButton from "../../components/UI/Buttons/GoBackButton"
import { withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Leaflet extends React.Component {
    render() {
        const { leaflet } = this.props.location && this.props.location.state;
        return (
            <Translation>
                {t =>
                    <div className="ml-5">
                        <GoBackButton goBack={() => this.props.history.push("/leaflets")}>
                            {t("goBack")}
                        </GoBackButton>
                        <div className="leaflet-frame">
                            <div className='framePdf'>
                                <iframe src={leaflet.pdfLink} />
                            </div>
                        </div>
                    </div>
                }
            </Translation>

        );
    }
}

export default withRouter(Leaflet);