import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'

class SideMenuItem extends Component {
    render() {
        return (
            <>
                {this.props.to &&
                    <Link to={this.props.to} onClick={this.props.onClick} target={this.props.target}>
                        <div className={"menu-item " + (this.props.location.pathname === this.props.to ? "active" : "")}>
                            {this.props.label}
                        </div>
                    </Link>
                }
                {!this.props.to &&
                    <a href={this.props.href} onClick={this.props.onClick} target={this.props.target}>
                        <div className={"menu-item " + (this.props.location.pathname === this.props.to ? "active" : "")}>
                            {this.props.label}
                        </div>
                    </a>
                }
            </>
        )
    }
}

export default withRouter(SideMenuItem);
