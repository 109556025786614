import React from "react"
import { Link } from 'react-router-dom'
import Modal from "../UI/Modal/CustomModal"
import Privacy from "../../pages/Privacy";
import Tac from "../../pages/Tac";

function Accepts(props) {
    return (
        <>
            <div className="form-check mt-2">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept1" name="accept1"
                        disabled={props.disabled}
                        defaultChecked={props.user && props.user.accept1}
                        ref={props.register({
                            required: "Campo obbligatorio"
                        })}
                    />
                    <label className="custom-control-label input-required" htmlFor="accept1">
                        {props.isTotem &&
                            <Modal trigger={
                                <button type="button" className="btn btn-link" style={{ padding: "0px" }}>
                                    <span style={{ fontSize: "18px" }}>
                                        Accetto i termini e le condizioni di utilizzo*
                                    </span>
                                </button>
                            }>
                                <Tac isTotem={true} />
                            </Modal>
                        }
                        {!props.isTotem && <>Accetto i <Link to="/tac" target='_blank'>termini e le condizioni di utilizzo*</Link></>}
                    </label>
                    <div className="form-control-invalid">
                        {props.errors.accept1 && props.errors.accept1.message}
                    </div>
                </div>
            </div>

            <div className="form-check mt-2">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept2" name="accept2"
                        disabled={props.disabled}
                        defaultChecked={props.user && props.user.accept2}
                        ref={props.register({
                            required: "Campo obbligatorio"
                        })}
                    />
                    <label className="custom-control-label input-required" htmlFor="accept2">
                        {props.isTotem &&
                            <Modal trigger={
                                <button type="button" className="btn btn-link" style={{ padding: "0px" }}>
                                    <span style={{ fontSize: "18px" }}>
                                        Accetto l'informativa sulla privacy*
                                    </span>
                                </button>
                            }>
                                <Privacy isTotem={true} />
                            </Modal>
                        }
                        {!props.isTotem && <>Accetto <Link to="/privacy" target='_blank'>l'informativa sulla privacy*</Link></>}
                    </label>
                    <div className="form-control-invalid">
                        {props.errors.accept2 && props.errors.accept2.message}
                    </div>
                </div>
            </div>

            <div className="form-check mt-2">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept3" name="accept3"
                        defaultChecked={props.user && props.user.accept3}
                        ref={props.register}
                    />
                    <label className="custom-control-label" htmlFor="accept3">
                        Consenso comunicazioni per finalità di marketing
                        </label>
                    <div className="form-control-invalid">
                        {props.errors.accept3 && props.errors.accept3.message}
                    </div>
                </div>
            </div>

            <div className="form-check mt-2">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept4" name="accept4"
                        defaultChecked={props.user && props.user.accept4}
                        ref={props.register}
                    />
                    <label className="custom-control-label" htmlFor="accept4">
                        Consenso per finalità di profilazione
                        </label>
                    <div className="form-control-invalid">
                        {props.errors.accept3 && props.errors.accept3.message}
                    </div>
                </div>
            </div>

            <div className="form-check mt-2">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept5" name="accept5"
                        defaultChecked={props.user && props.user.accept5}
                        ref={props.register}
                    />
                    <label className="custom-control-label" htmlFor="accept5">
                        Consenso per la ricezione di mail
                        </label>
                    <div className="form-control-invalid">
                        {props.errors.accept5 && props.errors.accept5.message}
                    </div>
                </div>
            </div>

            <div className="form-check mt-2">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept6" name="accept6"
                        defaultChecked={props.user && props.user.accept6}
                        ref={props.register}
                        disabled={!props.mobilePhone || props.errors.mobilePhone}
                    />
                    <label className="custom-control-label" htmlFor="accept6">
                        Consenso per la ricezione di SMS o Whatsapp
                        </label>
                    <div className="form-control-invalid">
                        {props.errors.accept6 && props.errors.accept6.message}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Accepts;



