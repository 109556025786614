import React from "react";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
import qs from "query-string";
import { withRouter } from "react-router";
import { Redirect, Link } from 'react-router-dom'
import config from "../../config/config";
import MediaQuery from 'react-responsive'

class Home extends React.Component {
    render() {
        const origin = qs.parse(this.props.location.search).origin;
        if (origin === config.PLATFORM_ANDROID || origin === config.PLATFORM_IOS) {
            return (
                <Redirect to={{
                    pathname: '/signup',
                    state: { origin: origin }
                }} />
            );
        } else {
            return (
                <>
                    <HomeCarousel />
                    {/* Only on mobile device */}
                    <MediaQuery maxDeviceWidth={700}>
                        <div className="row mt-5 text-center">
                            <div className="col-md-6 mb-4">
                                <Link to="/login" className="btn btn-lg btn-success" style={buttonStyles}>
                                    Accedi
                                </Link>
                            </div>
                            <div className="col-md-6 mb-4">
                                <Link to="/signup" className="btn btn-lg btn-danger" style={buttonStyles}>
                                    Registrati
                                </Link>
                            </div>
                        </div>
                    </MediaQuery>
                </>
            );
        }
    }
}

export default withRouter(Home);

const buttonStyles = {
    minWidth: "250px",
    textTransform : "uppercase"
}
