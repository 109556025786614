import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import config from '../../config/config';
import axios from 'axios';
import { authHeader } from '../../utils/authHeader';

export default function leaflets() {
    return (
        axios.get(`${config.baseUrl}/cli/cms/leaflets`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}