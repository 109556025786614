import store from "../redux/store/index";

export function authHeader() {
    // return authorization header with jwt token
    let user = store.store.getState().user;
    if (user.user && user.jwt) {
        return { 'Authorization': 'Bearer ' + user.jwt };
    } else {
        return {};
    }
}