import React, { Component } from 'react';
import StoreCard from '../../components/MainComponent/Cards/StoreCard';
import Store from './Store/Store';
import Pagination from '../../components/UI/Pagination/NewPagination'
import GlobalMap from '../../components/UI/Maps/GlobalMap';
import { storesService } from "../../services/basis/StoresService"
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import { debounce } from "lodash"
import * as actionCreators from "../../redux/store/actions/index"

class Stores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            stores: [],
            selectedStore: null,
            page: 0,
            totalPages: null,
            query: "",
            withDistance: false
        }
    }

    componentDidMount() {
        storesService.stores(this.state.page, this.state.query)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        stores: response.data,
                        isLoaded: true,
                        totalPages: response.totalPages,
                        page: response.number,
                    })
                }
            }).catch((error) => {
                console.log(error);
            })

        if (navigator.geolocation) {
            if (!this.props.latitude && !this.props.longitude) {
                navigator.geolocation.getCurrentPosition(
                    pos => {
                        this.props.coordinate(
                            pos.coords.latitude,
                            pos.coords.longitude
                        )
                    }
                )
            }
        }
    }

    readStoresByPosition = (page, latitude, longitude, query) => {
        if (latitude && longitude) {
            storesService.storesCoordinate(page, latitude, longitude, query)
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            stores: response.data,
                            isLoaded: true,
                            totalPages: response.totalPages,
                            page: response.number,
                            withDistance: true
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    readStores = (page, query) => {
        storesService.stores(page, query)
            .then(response => {
                this.setState({
                    stores: response.data,
                    isLoaded: true,
                    totalPages: response.totalPages,
                    page: response.number,
                    withDistance: false
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    selectStore = (store) => {
        if (this.state.selectedStore) {
            const currentStoreId = this.state.selectedStore.id;
            if (store.id !== currentStoreId) {
                this.setState({
                    selectedStore: store,
                })
            }
        } else {
            this.setState({
                selectedStore: store,
            })
        }
    }

    changePage = (page) => {
        if (page >= 0 && page < this.state.totalPages) {
            if (this.state.withDistance) {
                this.readStoresByPosition(page, this.props.latitude, this.props.longitude, this.state.query)
            } else {
                this.readStores(page, this.state.query);
            }
        }
    }

    onChange = (e) => {
        this.setState({ query: e.target.value });
    }

    handleInput = debounce((input) => {
        if (this.state.withDistance) {
            this.readStoresByPosition(0, this.props.latitude, this.props.longitude, input)
        } else {
            this.readStores(0, input)
        }
    }, 1000)

    componentDidUpdate(prevProps, prevState) {
        if (prevState.query !== this.state.query) {
            this.handleInput(this.state.query)
        }
    }

    render() {
        const { isLoaded, stores, selectedStore, page, totalPages, query } = this.state;
        const { latitude, longitude } = this.props;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <div>
                            <div className="row mb-2">
                                <div className="col-md">
                                    <h3>{t('stores')}</h3>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-5">
                                    <h6>{t("search")}</h6>
                                    <input type="text" className="form-control" value={query} onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="row mb-4">
                                        <div className="col-md">
                                            <h6>{t("orderBy")}</h6>
                                            <div className="btn-group" role="group">
                                                <button id="btn-name" className="btn btn-secondary btn-name"
                                                    onClick={() => this.readStores(0, query)}>
                                                    {t("name")}
                                                </button>
                                                <button id="btn-distance" disabled={latitude && longitude ? false : true} className="btn btn-secondary"
                                                    onClick={() => this.readStoresByPosition(0, latitude, longitude, query)}>
                                                    {t("distance")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md">
                                            {stores && stores.map((item) => (
                                                <div key={item.id}>
                                                    <StoreCard item={item} selectStore={this.selectStore} />
                                                </div>
                                            ))}
                                            {totalPages > 1 &&
                                                <Pagination
                                                    page={page}
                                                    totalPages={totalPages}
                                                    changePage={this.changePage}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    {selectedStore && <Store selectedStore={selectedStore} />}
                                    {!selectedStore &&
                                        <div style={{ marginTop: "85px" }}>
                                            <GlobalMap stores={stores} lat={44.701111} lng={8.034818} zoom={7} />
                                        </div>}
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        latitude: state.user.latitude,
        longitude: state.user.longitude,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        coordinate: (latitude, longitude) => dispatch(actionCreators.coordinate(latitude, longitude)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Stores);