import React from "react";
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";

const style = {
    width: "75%",
    bordeRadius: "20px",
    overflowY: "auto",
    height: "100%"
}

export default (props) => (
    <Translation>
        {t =>
            <Popup trigger={props.trigger} modal contentStyle={{ ...props.style, ...style }}>
                {close => (
                    <div className="modal-content" style={{ border: "0px" }}>
                        <div className="modal-header">
                            <button type="button" onClick={close} className="close">
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {props.children}
                        </div>
                    </div>
                )}
            </Popup>
        }
    </Translation>
);

