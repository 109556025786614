/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: localStorage.getItem("user") != "undefined" && localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : null,
    jwt: null,
    latitude: null,
    longitude: null
}

const userReducer = createReducer(initialState, {
    [actionTypes.LOGIN]: (state, action) => { state.user = action.user, state.jwt = action.jwt },
    [actionTypes.LOGOUT]: (state, action) => { state.user = action.user },
    [actionTypes.USER_UPDATE]: (state, action) => { state.user = action.user },
    [actionTypes.USER_DELETE]: (state, action) => { state.user = action.user },
    [actionTypes.IS_LOGGED]: (state, action) => { state.user = action.user },
    [actionTypes.CARD_DISABLE]: (state, action) => { state.user.cards[0] = action.card },
    [actionTypes.COORDINATE]: (state, action) => {
        state.latitude = action.latitude,
        state.longitude = action.longitude
    },
})

export default userReducer;

