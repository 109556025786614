// Redux
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk"
import user from "./reducers/user"
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist"

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['user']
}

const rootReducer = combineReducers({
    user: user,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);

export default { store, persistor }; 