import React from 'react'
import { Link } from 'react-router-dom';

function Privacy(props) {
    return (
        <>
            <div>
                <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
                    Informativa ai Titolari della Carta Fedeltà "I AM Despar Card"
                    sul trattamento dei dati personali ex art. 13 Regolamento 2016/679 (UE)
                </h4>
                <p style={{ textAlign: "justify" }}>
                    <b>1. Titolare del Trattamento.</b>
                </p>
                <div
                    style={{
                        border: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        padding: "0cm 0cm 1.0pt 0cm"
                    }}
                >
                    <p style={{ textAlign: "justify", border: "none", padding: "0cm" }}>
                        Centro 3 A S.p.A., con sede legale in Strada San Bartolomeo n.276/A, Fraz.
                        Quarto inferiore, 14030 Asti (AT), Codice Fiscale 02457360044, Partita IVA
                        01206220053 (nel seguito, "Centro 3 A" o "Titolare del trattamento"),
                        nella sua qualità di Titolare del trattamento, attribuisce la massima
                        importanza alla protezione dei dati personali del Titolare della Carta
                        Fedeltà "I AM Despar Card" e, ai sensi e per gli effetti di cui all'art.
                        13 del Regolamento UE 2016/679 (nel seguito "GDPR"), desidera informarla
                        riguardo a quali dati vengono raccolti in fase di registrazione e adesione
                        al Programma Fedeltà, a come vengono trattati, quale condizione rende
                        lecito il trattamento e per quanto tempo conserverà i suoi dati. Il
                        trattamento sarà costantemente improntato ai principi di correttezza,
                        liceità, trasparenza e di tutela della sua riservatezza e dei suoi
                        diritti.
                    </p>
                </div>
            </div>
            <div>
                <p style={{ textAlign: "justify", marginTop: "15px" }}>
                    <b>2. Responsabile per la Protezione Dati (DPO).</b>
                </p>
                <div
                    style={{
                        border: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        padding: "0cm 0cm 1.0pt 0cm"
                    }}
                >
                    <p style={{ textAlign: "justify", border: "none", padding: "0cm" }}>
                        Il Titolare della Carta Fedeltà "I AM Despar Card" può contattare il
                        Responsabile della Protezione dei Dati (DPO), all'indirizzo e-mail{" "}
                        <b>dpo@centro3a.it </b>(di seguito DPO).
                    </p>
                </div>
                <p style={{ textAlign: "justify", marginTop: "15px" }}>
                    <b>3. Dati trattati.</b>
                </p>
                <div
                    style={{
                        border: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        padding: "0cm 0cm 1.0pt 0cm"
                    }}
                >
                    <p style={{ textAlign: "justify" }}>
                        Raccogliamo, direttamente dal Titolare della Carta Fedeltà "I AM Despar
                        Card", quale soggetto Interessato al trattamento, le seguenti categorie di
                        dati comuni, in base alle finalità perseguite e come meglio precisate nella
                        presente informativa:
                    </p>

                    <ul>
                        <li className="mb-2">
                            dati anagrafici e di contatto, quali nome e cognome, data di nascita, sesso,
                            indirizzo di residenza o domicilio, numero di cellulare, indirizzo e-mail;
                        </li>
                        <li className="mb-2">
                            dati relativi all'adesione, alla partecipazione, all'uso del servizio di
                            assistenza della Carta Fedeltà "I AM Despar Card";
                        </li>
                        <li className="mb-2">
                            dati di navigazione e dati di registrazione al sito
                            www.desparsupermercati.it e all'App "I AM Despar Nord Ovest" (username e
                            password);
                        </li>
                        <li className="mb-2">
                            dati facoltativi, relativi alla sua condizione familiare, ai suoi interessi,
                            al suo titolo di studio, ai suoi animali ed alla sua professione;
                        </li>
                        <li className="mb-2">
                            dati di acquisto, relativi al volume di spesa complessivo progressivamente
                            realizzato e/o al dettaglio dei prodotti acquistati;
                        </li>
                        <li className="mb-2">
                            dati di pagamento.
                        </li>
                    </ul>
                </div>

                <p style={{ textAlign: "justify", marginTop: "15px" }}>
                    <b>4. Finalità e base giuridica del Trattamento, periodo di conservazione.</b>
                </p>
                <div
                    style={{
                        border: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        padding: "0cm 0cm 1.0pt 0cm"
                    }}
                >
                    <p style={{ textAlign: "justify" }}>
                        Il Programma Fedeltà è basato sull'utilizzo della "I AM Despar Card" che, alle condizioni di cui
                        al {props.isTotem ? "Regolamento" : <Link to="/tac"><u>Regolamento</u></Link>}, dà diritto al Titolare di
                        accumulare punti convertibili in buoni spesa presso tutti i supermercati ad
                        insegna Despar, Despar Express, Eurospar, La Bottega, presenti sul
                        territorio italiano e aderenti all'Iniziativa. Trattiamo i suoi dati per le
                        seguenti finalità, connesse all'esecuzione del Programma "I AM Despar":
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>4.A Per finalità di fidelizzazione:</b> per l'attribuzione al Titolare
                        dei vantaggi connessi all'utilizzo della Carta Fedeltà, quali l'accumulo dei
                        Punti e la conversione dei punti in buoni spesa.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>4.B Per finalità di ricezione e riscontro delle richieste di informazione ed assistenza:{" "}</b>
                        per poter ricevere le sue comunicazioni così come per poterle fornire
                        assistenza ed inviare comunicazioni relative al Programma, con le modalità descritte nel{" "}
                        {props.isTotem ? "Regolamento" : <Link to="/tac"><u>Regolamento</u></Link>}.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>Periodo di conservazione:</b> i dati personali raccolti per le finalità di
                        cui ai punti 4.A e 4.B saranno conservati per tutta la durata della sua
                        adesione al Programma "I AM Despar", salvo non debbano essere conservati per
                        ulteriori finalità e, a seguito della cessazione, per 10 anni, fatti salvi
                        diversi termini di conservazione previsti dalla Legge o da normative
                        speciali o regolamentari di settore.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>4.C Per finalità di creazione e gestione dell'account personale: </b>per
                        la creazione e l'accesso, mediante credenziali personali (username e
                        password) al suo account "I AM Despar" tramite il sito
                        www.desparsupermercati.it o tramite l'App "I Am Despar Nord Ovest", per
                        identificarla ed assisterla in caso di difficoltà d'accesso all'account
                        ovvero in caso di smarrimento o sottrazione della Carta Fedeltà, per
                        consentirle di accumulare i punti, per impostare le sue scelte in materia di
                        consensi Privacy.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>Periodo di conservazione:</b> i dati personali raccolti per le finalità di
                        cui al punto 4.C verranno conservati per tutta la durata della sua adesione
                        al programma "I AM Despar", fatta salva la sua richiesta di cancellazione
                        dell'account.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        La <b>base giuridica del trattamento</b> per le finalità di cui ai
                        precedenti punti 4.A, 4.B e 4.C, ai sensi dell'art. 6.1, lett. b) GDPR, è la
                        necessità di dare esecuzione ad un contratto di cui l'Interessato è parte.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>4.D Per finalità amministrative, contabili e legali connesse alla gestione del Programma:{" "}</b>
                        per tutte le finalità amministrative, contabili e, comunque, previste dalla
                        normativa vigente, a qualunque titolo connesse alla gestione del Programma
                        "I AM Despar".
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        La <b>base giuridica del trattamento</b> per le finalità di cui al punto 4.D
                        è la necessità di adempiere ad obblighi previsti dalla normativa nazionale e
                        sovranazionale ai sensi dell'art. 6.1 lett. c) GDPR.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>Periodo di conservazione:</b> i dati personali raccolti per le finalità di
                        cui al punto 4.D verranno conservati per il periodo previsto dalla normativa
                        applicabile.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        Per tutte le suddette finalità (di cui ai punti 4.A, 4.B, 4.C e 4.D)
                        <b> il conferimento dei dati è obbligatorio</b>.
                        Il rifiuto di fornire i dati
                        non consente al Titolare della Carta Fedeltà l'iscrizione e l'esecuzione del
                        Programma "I AM Despar" e non consente, altresì, l'adempimento agli obblighi
                        di Legge gravanti sul Titolare del trattamento.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        <b>4.E Per finalità di marketing: </b>previo consenso,<b> </b>utilizzeremo i
                        suoi dati per inviarle comunicazioni commerciali tramite telefono, posta
                        cartacea e/o con modalità di contatto automatizzate, quali posta
                        elettronica, sms, notifiche tramite app e messaggistica istantanea. L'invio
                        di comunicazioni potrà anche avvenire per il tramite del sito
                        www.desparsupermercati.it ovvero tramite l'App "I AM Despar Nord Ovest". Le
                        comunicazioni commerciali potranno riguardare promozioni legate alla Carta
                        Fedeltà, così come offerte, promozioni o sconti su prodotti o servizi.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        La <b>base giuridica del trattamento</b> risiede nel suo consenso,
                        esplicitamente reso per tali finalità, facoltativo e revocabile in qualsiasi
                        momento a norma dell'art. 6.1 lett. a) GDPR.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <b>4.F Per finalità di profilazione: </b>previo consenso, utilizzeremo i
                        suoi dati per creare un profilo cliente ed esaminare le sue preferenze, le
                        sue abitudini, i prodotti acquistati, al fine di inviarle comunicazioni
                        commerciali personalizzate in base ai suoi interessi.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        La <b>base giuridica del trattamento</b> risiede nel suo consenso,
                        esplicitamente reso per tali finalità, facoltativo e revocabile in qualsiasi
                        momento a norma dell'art. 6.1 lett. a) GDPR.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <b>Periodo di conservazione:</b> i dati raccolti per le finalità di cui ai
                        punti 4.E e 4.F sarà di 24 mesi dalla registrazione.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        Per tutte le suddette finalità (di cui ai punti 4.E e 4.F)
                        <b> il conferimento dei dati facoltativo</b>.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <b>4.G Per finalità di prevenzione e repressione</b>: utilizzeremo,
                        all'occorrenza, i suoi dati per finalità di prevenzione e repressione di
                        frodi, abusi, attività fraudolente realizzate anche tramite il sito
                        www.desparsupermercati.it o tramite l'App "I AM Despar Nord Ovest".
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        La <b>base giuridica del trattamento</b> risiede nell'interesse legittimo ai
                        sensi dell'art. 6.1 lett. f) GDPR.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <b>Periodo di conservazione: </b>i dati raccolti secondo i canali
                        tradizionali saranno conservati per un periodo massimo di 5 anni; i dati
                        trattati per finalità di prevenzione e repressione di frodi, abusi, attività
                        fraudolente realizzate tramite il sito www.desparsupermercati.it o tramite
                        l'App "I AM Despar Nord Ovest" saranno conservati per un periodo massimo di
                        180 giorni.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <b>4.H</b>{" "}
                        <b>Per finalità di accertamento, esercizio o difesa di diritti:</b>{" "}
                        utilizzeremo, all'occorrenza, i suoi dati per accertare, esercitare o
                        difendere i diritti del Titolare del trattamento in sede di reclamo, azione,
                        risoluzione giudiziale e/o stragiudiziale.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        La <b>base giuridica del trattamento</b> risiede nell'interesse legittimo,
                        ai sensi dell'art. 6.1 lett. f) GDPR.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <b>Periodo di conservazione: </b>i dati trattati per le finalità di cui al
                        punto 4.H saranno conservati per tutta la durata del reclamo, dell'azione o
                        della risoluzione giudiziale e/o stragiudiziale, sino allo spirare dei
                        termini di esperibilità delle azioni di tutela giudiziali e/o dei mezzi di
                        impugnazione.
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        Decorsi i termini di conservazione sopra indicati, i dati saranno distrutti
                        o resi anonimi, compatibilmente con le procedure tecniche di cancellazione e
                        di backup.
                    </p>
                </div>

                <div
                    style={{
                        border: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        padding: "0cm 0cm 1.0pt 0cm"
                    }}
                >
                    <p style={{ textAlign: "justify", marginTop: "15px" }}>
                        <b>5. Soggetti autorizzati al trattamento dei dati: </b>
                        i suoi dati saranno trattati dal personale dipendente
                        del Titolare del trattamento, addetto alle funzioni aziendali deputate al
                        perseguimento delle finalità sopra indicate, espressamente autorizzato al
                        trattamento ed adeguatamente munito di istruzioni operative.
                    </p>
                </div>

                <p className="MsoNormal" style={{ textAlign: "justify", marginTop: "15px" }}>
                    <b>6. Soggetti a cui i suoi dati possono essere comunicati:</b> i suoi dati
                    potranno essere comunicati a soggetti esterni, operanti in qualità di
                    Titolari del trattamento quali: banche ed istituti di credito, autorità di
                    vigilanza e controllo ed ogni soggetto pubblico legittimato, come l'autorità
                    giudiziaria e/o di pubblica sicurezza, società controllanti/controllate o,
                    comunque, del gruppo, per finalità amministrative interne.
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    I suoi dati potranno, inoltre, essere trattati per conto del Titolare del
                    trattamento da soggetti esterni debitamente nominati "Responsabili del
                    trattamento", quali: società controllanti/controllate o, comunque, del
                    gruppo per servizi infragruppo; consulenti e liberi professionisti, anche in
                    forma associata; società che erogano servizi informatici (manutenzione,
                    hosting, e-mailing, invio di sms, invio di notifiche push e messaggistica
                    istantanea); società esterne di digital marketing; società che erogano
                    servizi di postalizzazione.
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    I suoi dati non verranno diffusi; verranno conservati su server ubicati
                    presso la sede del Titolare del trattamento o comunque sul territorio
                    italiano, e non verranno trasferiti all'estero.
                </p>
                <div
                    style={{
                        border: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        padding: "0cm 0cm 1.0pt 0cm"
                    }}
                >
                </div>

                <p className="MsoNormal" style={{ textAlign: "justify", marginTop: "15px" }}>
                    <b>7. Diritti dell'Interessato: </b>

                    il GDPR sancisce una serie di diritti in favore dell'Interessato al
                    trattamento il quale ha, pertanto, il diritto di ottenere la cancellazione
                    (diritto all'oblio), la limitazione, l'aggiornamento, la rettificazione,
                    la portabilità, l'opposizione al trattamento dei suoi dati personali e, in
                    generale, può esercitare tutti i diritti previsti dagli artt. 15, 16, 17,
                    18, 19, 20, 21, 22 del GDPR. In particolare, l'interessato ha diritto:
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    a) di ottenere: la conferma
                    dell'esistenza o meno di dati personali che lo riguardano, anche se non
                    ancora registrati, e la loro comunicazione in forma intelligibile;
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    b) di effettuare reclamo presso l'Autorità di controllo (Garante Privacy);
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    c) di ottenere l'indicazione:
                </p>

                <ul>
                    <li>dell'origine dei suoi dati personali trattati dal Titolare del trattamento;</li>
                    <li>delle finalità e delle modalità del trattamento;</li>
                    <li>della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici;</li>
                    <li>degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell'art.5, co.2, GDPR;</li>
                    <li>dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a
                        conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o di incaricati.
                    </li>
                </ul>

                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    d) di ottenere:
                </p>

                <ul>
                    <li>l’aggiornamento, la rettificazione, ovvero, quando vi ha interesse, l'integrazione dei suoi dati;</li>
                    <li>la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di Legge,
                        compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;
                    </li>
                    <li>
                        l'attestazione che le attività di cui al presente articolo sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai
                        quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si riveli impossibile o comporti un impiego di mezzi
                        manifestamente sproporzionato rispetto al diritto tutelato;
                    </li>
                    <li>la portabilità dei suoi dati.</li>
                </ul>

                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    e) di opporsi, in tutto o in parte, al trattamento dei suoi dati:
                </p>

                <ul>
                    <li>per motivi legittimi, al trattamento dei suoi dati personali ancorché pertinenti allo scopo della raccolta;</li>
                    <li>in ogni caso, al trattamento dei suoi dati personali a fini di invio di materiale pubblicitario, di vendita diretta, per il compimento di
                        ricerche di mercato o di comunicazioni commerciali.
                    </li>
                </ul>

                <p className="MsoNormal">
                    Di seguito il link per accedere alla pagina del sito del Garante della Privacy, ove sono pubblicati i modelli, con cui gli  interessati possono
                    esercitare  i propri diritti. L’indirizzo del sito dell’Autorità Garante è il seguente:
                    <a href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/2014184" target="_blank" rel="noopener noreferrer">
                        <u> https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/2014184</u>
                    </a>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    La informiamo che l'opposizione alla raccolta, all'elaborazione o
                    all'utilizzo dei suoi dati da parte del Titolare del trattamento potrebbe
                    comportare l'impossibilità di fruire, in tutto o in parte, dei vantaggi
                    offerti dal Programma "I AM Despar".
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    Può esercitare i suoi diritti scrivendo all'indirizzo e-mail
                    privacy@centro3a.it ovvero inviare una lettera raccomandata al Centro 3 A S.p.A.
                    con sede legale in Strada San Bartolomeo n.276/A, 14030 Frazione Quarto
                    Inferiore, Asti (AT) o, ancora, contattare il Responsabile della Protezione dei Dati (DPO) ai
                    recapiti indicati al punto 2 della presente informativa.
                </p>


                <p style={{ marginBottom: ".0001pt", background: "white" }}>
                    <i>
                        <span style={{ fontSize: "10.0pt" }}>
                            Ultima modifica: 18/12/2019
                        </span>
                    </i>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    &nbsp;
                </p>
                {/*
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    Autorizzo il trattamento dei miei dati per le finalità di Marketing, di cui al punto <u>4.E</u>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    Si
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    No
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    &nbsp;
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    Autorizzo il trattamento dei miei dati per le finalità di Profilazione, di
                    cui al punto <u>4.F</u>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    Si
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    No
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    &nbsp;
                </p>
                 */}
            </div >
        </>

    );
}

export default Privacy
