import React from 'react'
import getLeaflets from "./Service"
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom"

export default class Leaflets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            leaflets: [],
        };
    }

    componentDidMount() {
        getLeaflets().then(response => {
            if (response.data.length > 0) {
                this.setState({
                    isLoaded: true,
                    leaflets: response.data,
                })
            }
        });
    }

    render() {
        const { isLoaded, leaflets } = this.state;
        if (isLoaded) {
            return (
                <Translation>
                    {t =>
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="mb-4">{t("leaflets")}</h3>
                                </div>
                            </div>

                            <div className="row ml-4 mb-3">
                                {leaflets && leaflets.map(item => (
                                    <div key={item.id} className="col-sm-4">
                                        <Link to={{ pathname: "/leaflet", state: { leaflet: item } }}>
                                            <div key={item.id}>
                                                <h6>{item.title}</h6>
                                                <div className="leaflet-image">
                                                    <img src={item.coverImage} alt=""></img>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}
