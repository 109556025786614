import React from 'react'
import { Link } from 'react-router-dom';

const AppConditions = () => (
    <>
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
            Termini e condizioni generali
        </h3>
        <p style={{ textAlign: "justify" }}>
            “I AM Despar Nord Ovest” (nel seguito anche “l’App”) è l’App dedicata al
            programma “I AM Despar”, a mezzo della quale il Titolare della “I AM Despar
            Card” può registrarsi, attivare la propria Card e creare il proprio account
            personale.
        </p>
        <p style={{ textAlign: "justify" }}>
            Qui di seguito sono indicati i Termini e le Condizioni Generali di uso
            dell’App che, unitamente ai documenti ivi richiamati, indicano le
            condizioni alle quali il Titolare della “I AM Despar Card” (nel seguito,
            anche “l’Utente”) può accedere ai contenuti ed ai servizi resi disponibili
            sull’App.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>1. Titolarità dell'App.</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            Titolare dell’App è Centro 3 A S.p.A. (nel seguito, anche “Centro 3A”),
            Cod. Fisc. 02457360044, P. IVA 01206220053, con sede in Strada San
            Bartolomeo n.276/A, Fraz. Quarto Inferiore 14030 Asti (AT).
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>2. Contenuti e Condizioni di utilizzo dell'App.</b>
        </p>

        <p style={{ textAlign: "justify" }}>
            <b>2.1</b> L’App mette a disposizione dell’Utente la possibilità di registrarsi,
            con le modalità di cui al successivo art. 3, di attivare la propria “I AM
            Despar Card” e di creare un Account personale. L’App, così come il Sito    
            <a href="http://www.desparsupermercati.it"> www.desparsupermercati.it </a>
            dedicato all’Iniziativa, non offrono la vendita di prodotti o servizi e
            sono unicamente finalizzati alla gestione del programma fedeltà “I AM
            Despar”.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>2.2</b> Prima di procedere alla registrazione, il Titolare della “I AM Despar
            Card” è invitato a leggere con attenzione i Termini e le Condizioni
            Generali che regolano l’accesso e l’utilizzo dell’App. I Termini e le
            Condizioni Generali comprendono, oltre a quanto qui nel seguito indicato,
            ogni nota, avviso legale, contenuto contrattuale o anche solo informativo
            pubblicati ovvero richiamati attraverso link di collegamento, ivi comprese
            le informazioni relative alla Privacy policy.
        </p>
        <p style={{ textAlign: "justify" }}>
            L’utilizzo dei servizi fruibili per il tramite dell’App, per i quali è
            richiesta la registrazione, implica l’accettazione sia dei Termini e delle
            Condizioni Generali sia dei Termini e delle Condizioni Speciali previsti
            per l’Utente.
        </p>
        <p style={{ textAlign: "justify" }}>
            Per il caso in cui l’Utente utilizzasse i servizi dell’App per conto di
            Terzi, accettando Termini e Condizioni, Generali o Speciali, dichiara e
            garantisce di avere il potere di rappresentare, obbligare e vincolare il
            Terzo, liberando Centro 3A da ogni e qualsivoglia responsabilità nei
            riguardi del Terzo.
        </p>
        <p style={{ textAlign: "justify" }}>
            Per il caso in cui l’Utente ritenesse di non accettare le pattuizioni di
            cui ai Termini e Condizioni Generali così come ai Termini e Condizioni
            Speciali, è invitato a non utilizzare l’App ed i servizi offerti tramite
            l’App da Centro 3A.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>2.3</b> Centro 3A si riserva il diritto di aggiornare e modificare, in tutto o
            in parte ed in qualsiasi momento, sia Termini e Condizioni Generali sia
            Termini e Condizioni Speciali, così come eventuali integrazioni
            contrattuali da queste richiamati, ivi compresa l’Informativa sulla propria
            Privacy policy. Il Titolare della “I AM Despar Card” è invitato, pertanto,
            a prenderne periodicamente visione, poiché i contenuti, così come
            modificati, avranno vigore dal momento della pubblicazione e si
            intenderanno da quel momento conosciuti dall’Utente.
        </p>
        <p style={{ textAlign: "justify" }}>
            Per il caso in cui l’Utente ritenesse di non accettare le modifiche così
            apportate a Termini e Condizioni Generali ovvero a Termini e Condizioni
            Speciali, è invitato a non utilizzare l'App ed i servizi offerti da
            Centro 3A e, laddove fosse già registrato, a procedere alla cancellazione
            dell’Account personale.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>2.4</b>I contenuti dell'App ed i servizi ivi offerti da Centro 3A sono
            destinati ad Utenti che siano persone fisiche, Clienti dei supermercati
            aderenti al programma “I AM Despar”, qualificate “Consumatori” ai sensi
            dell’art. 3, co. 1, lett. a), D. Lgs. 206/2005, maggiorenni, residenti o
            domiciliati in Italia, nella repubblica di San Marino o nello Stato della
            Città del Vaticano.
        </p>
        <p style={{ textAlign: "justify" }}>
            Sono esclusi dalla partecipazione al Programma “I AM Despar” e, quindi, non
            è consentita la registrazione all'App coloro i quali rivestono la qualità
            di Professionisti, intendendosi per tali le persone fisiche e/o giuridiche
            che, in relazione all'acquisto di prodotti presso i supermercati aderenti
            all’Iniziativa, agiscono nell’esercizio della propria attività
            imprenditoriale, commerciale, professionale o artigianale.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>3. Registrazione dell’Utente.</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>3.1</b>I contenuti dell'App sono accessibili solo previa registrazione e creazione, da parte
            dell’Utente, di un Account personale, protetto da password.
        </p>
        <p style={{ textAlign: "justify" }}>
            In sede di registrazione, all’Utente verrà chiesto di indicare taluni dati
            anagrafici e di contatto, quali nome e cognome, data di nascita, sesso,
            indirizzo di residenza o domicilio, numero di cellulare, indirizzo e-mail,
            dati considerati obbligatori onde poter fruire dei servizi offerti da
            Centro 3A per il tramite dell'App, ed una serie di dati ulteriori, relativi
            alla sua condizione familiare, ai suoi interessi, al suo titolo di studio,
            ai suoi animali ed alla sua professione, dati considerati facoltativi e
            richiesti per le finalità indicate nell’apposita <Link to="/privacy"><u>Informativa</u></Link>.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>3.2</b> L’Utente si impegna e dichiara di fornire in sede di registrazione
            informazioni veritiere ed aggiornate, liberando Centro 3A da ogni e
            qualsivoglia responsabilità per il caso di dichiarazioni mendaci. È onere e
            responsabilità dell’Utente provvedere al tempestivo aggiornamento delle
            informazioni fornite.
        </p>
        <p style={{ textAlign: "justify" }}>
            L’Utente è tenuto alla conservazione delle proprie credenziali di accesso
            all’Account personale ed è responsabile per ogni eventuale uso dell'App da
            parte di chiunque vi acceda con le sue credenziali. L’Utente si impegna ad
            informare tempestivamente Centro 3A in caso di sospetta violazione del
            proprio Account personale, ovvero di utilizzo del proprio profilo da parte
            di terzi non autorizzati.
        </p>
        <p style={{ textAlign: "justify" }}>
            L’Utente può, in qualunque momento, procedere alla cancellazione del
            proprio Account personale, seguendo le indicazioni riportate nell’apposita
            sezione.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>3.3</b> Centro 3A si riserva la facoltà di sospendere o cancellare l’Account
            dell’Utente per il caso di violazione, da parte del Titolare della “I AM
            Despar Card”, del <Link to="/tac"><u>Regolamento</u></Link> dell’Iniziativa ovvero di mancato rispetto
            dei presenti Termini e Condizioni Generali.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>3.4</b> La cancellazione dell’Account personale comporta la cancellazione della
            “I AM Despar Card”, come indicato nel <Link to="/tac"><u>Regolamento</u></Link> dell’iniziativa.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>4. Interruzione dei servizi.</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            Centro 3A potrà sospendere temporaneamente l’accesso alL'App in caso di
            problemi tecnici o di esigenze manutentive, così come potrà modificare
            impostazioni e contenuti. Centro 3A potrà cessare in qualunque
            momento, in tutto o in parte, la prestazione dei servizi resi a mezzo dell'App,
            fatto salvo il rispetto degli impegni contrattualmente assunti a
            mezzo del Regolamento dell’Iniziativa.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>5. Collegamento a siti di terzi.</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            Centro 3A non assume alcuna responsabilità in ordine ai contenuti
            pubblicati su siti di soggetti terzi verso i quali eventualmente l'App
            rechi link di collegamento. L’Utente che decide di accedere ai contenuti di
            detti siti è tenuto a verificarne termini e condizioni di utilizzo e
            Privacy policy e si assume l’onere di ogni verifica od accorgimento
            opportuno contro virus o altri malware.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6. Privacy policy. Informativa agli Utenti sul trattamento dei
            dati personali ex artt. 13-14 Regolamento 2016/679 (UE).</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.1</b> Centro 3A attribuisce la massima importanza alla protezione dei dati
            personali dell’Utente, che dovrà sentirsi a proprio agio nel visitare e nel
            fruire dei servizi dell'App; per l’effetto, Centro 3A desidera informare
            l’Utente riguardo a quali dati vengono raccolti quando utilizza l'App e a
            come vengono trattati.
        </p>
        <p style={{ textAlign: "justify" }}>
            l'App può contenere link che conducono ai siti di Terzi; per l’ipotesi in
            cui l’Utente ritenesse di accedere a detti siti, è invitato a prendere
            conoscenza della relativa Privacy policy, poiché la presente informativa
            vige unicamente per il l'App.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.2</b> Manifestando accettazione alla presente Privacy policy, l’Utente
            accetta le seguenti condizioni di raccolta, di elaborazione, di uso e, in
            via generale, di trattamento dei suoi dati da parte di Centro 3A secondo
            principi di correttezza, liceità, trasparenza, in ossequio alle norme di
            cui al Regolamento 2016/679 (UE), nel seguito “GDPR”.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.3</b> A norma del GDPR, il Titolare del Trattamento è il soggetto
            responsabile del trattamento dei dati personali dell’Utente, che determina,
            fra l’altro, le finalità e le modalità di trattamento dei dati. Titolare
            del trattamento è Centro 3 A S.p.A., in persona del suo legale
            rappresentante, Cod. Fisc.02457360044, P. IVA 01206220053, con sede in
            Strada San Bartolomeo n.276/A, Fraz. Quarto Inferiore 14030 Asti (AT).
        </p>
        <p style={{ textAlign: "justify" }}>
            L’Utente, per tutto quanto concerne il trattamento dei suoi dati personali,
            può contattare il Titolare del trattamento all’indirizzo e-mail
            privacy@centro3a.it, ovvero può contattare il Responsabile della Protezione
            dei Dati (DPO) all’indirizzo e-mail dpo@centro3a.it.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.4</b> Il GDPR sancisce una serie di diritti in favore dell’Interessato al
            trattamento (vale a dire il soggetto i cui dati vengono trattati da Centro
            3A e, nel caso di specie, l’Utente), il quale ha, pertanto, il diritto di
            ottenere la cancellazione (diritto all’oblio), la limitazione,
            l’aggiornamento, la rettificazione, la portabilità, l'opposizione al
            trattamento dei suoi dati personali e, in generale, può esercitare tutti i
            diritti previsti dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del GDPR. In
            particolare, l’Interessato ha diritto:
        </p>
        <p style={{ textAlign: "justify" }}>
            a) di ottenere: la conferma dell'esistenza o meno di dati personali che lo
            riguardano, anche se non ancora registrati, e la loro comunicazione in
            forma intelligibile;
        </p>
        <p style={{ textAlign: "justify" }}>
            b) di effettuare reclamo presso l’Autorità di controllo (Garante Privacy);
        </p>
        <p style={{ textAlign: "justify" }}>
            c) di ottenere l'indicazione:
        </p>
        <ul>
            <li className="mb-2">
                dell'origine dei suoi dati personali trattati dal Titolare del trattamento;
            </li>
            <li className="mb-2">
                delle finalità e delle modalità del trattamento;
            </li>
            <li className="mb-2">
                della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici;
            </li>
            <li className="mb-2">
                degli estremi identificativi del titolare, dei responsabili e del
                rappresentante designato ai sensi dell'art.5, co.2, GDPR;
            </li>
            <li className="mb-2">
                dei soggetti o delle categorie di soggetti ai quali i dati
                personali possono essere comunicati o che possono venirne a
                conoscenza in qualità di rappresentante designato nel territorio
                dello Stato, di responsabili o di incaricati;
            </li>
        </ul>
        <p style={{ textAlign: "justify" }}>
            d) di ottenere:
        </p>
        <ul>
            <li className="mb-2">
                l’aggiornamento, la rettificazione ovvero, quando vi ha interesse,
                l'integrazione dei suoi dati;
            </li>
            <li className="mb-2">
                la cancellazione, la trasformazione in forma anonima o il blocco
                dei dati trattati in violazione di Legge, compresi quelli di cui
                non è necessaria la conservazione in relazione agli scopi per i
                quali i dati sono stati raccolti o successivamente trattati;
            </li>
            <li className="mb-2">
                l'attestazione che le attività di cui al presente articolo sono
                state portate a conoscenza, anche per quanto riguarda il loro
                contenuto, di coloro ai quali i dati sono stati comunicati o
                diffusi, eccettuato il caso in cui tale adempimento si riveli
                impossibile o comporti un impiego di mezzi manifestamente
                sproporzionato rispetto al diritto tutelato;
            </li>
            <li className="mb-2">
                la portabilità dei suoi dati;
            </li>
        </ul>
        <p style={{ textAlign: "justify" }}>
            e) di opporsi, in tutto e per tutto, al trattamento dei suoi dati:
        </p>
        <ul>
            <li className="mb-2">
                per motivi legittimi, al trattamento dei suoi dati personali
                ancorché pertinenti allo scopo della raccolta;
            </li>
            <li className="mb-2">
                in ogni caso, al trattamento dei suoi dati personali a fini di
                invio di materiale pubblicitario, di vendita diretta, per il
                compimento di ricerche di mercato o di comunicazioni commerciali.
            </li>
        </ul>
        <p style={{ textAlign: "justify" }}>
            Centro 3A informa che l’opposizione alla raccolta, all’elaborazione o
            all’utilizzo dei dati potrebbe comportare l’impossibilità di fruire, in
            tutto o in parte, dei contenuti dell'App.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.5</b> Centro 3A tratta i dati personali dell’Utente per poter offrire i
            servizi connessi al Programma “I AM Despar”. Nel seguito sono indicati
            quali dati vengono raccolti, per quali scopi ed in quali circostanze
            vengono condivisi con altri soggetti ovvero eventualmente trasferiti.
        </p>
        <p style={{ textAlign: "justify" }}>
            I sistemi informatici e le procedure software preposte al funzionamento
            dell’App acquisiscono automaticamente, durante l’utilizzo, alcune
            informazioni la cui trasmissione è implicita nell’uso dei protocolli di
            comunicazione internet; queste informazioni riguardano le abitudini degli
            Utenti nell’utilizzo dell’App, quante volte l’App viene scaricata, quanto
            viene utilizzata e quali sezioni vengono fruite, tutte informazioni che non
            vengono associate ad Utenti identificati e vengono utilizzate al solo fine
            di trarre informazioni statistiche anonime sull’uso dell’App e per
            controllarne il corretto funzionamento. Tali informazioni sono conservate
            presso server in cloud anche al di fuori del territorio UE e vengono
            cancellate decorsi i termini di Legge.
        </p>
        <p style={{ textAlign: "justify" }}>
            Previa autorizzazione dell’Utente e con le medesime modalità, vengono
            acquisite informazioni sulla localizzazione dell’Utente, necessarie al fine
            di calcolare la distanza dai PdV aderenti all’Iniziativa I AM Despar,
            informazioni non registrate e non conservate da Centro 3A.
        </p>
        <p style={{ textAlign: "justify" }}>
            L’Utente, per poter fruire dei servizi riservati ai Titolari della “I AM
            Despar Card”, deve registrarsi tramite l’apposita sezione, creando un
            Account personale e comunicando i dati richiesti nell’apposito form. Centro
            3A raccoglie in questo modo, oltre a username e password, informazioni in
            base alle quali è possibile, direttamente o indirettamente, identificare la
            persona dell’Utente, tra i quali nome e cognome, data di nascita, sesso,
            indirizzo di residenza o domicilio, numero di telefono, indirizzo e-mail.
        </p>
        <p style={{ textAlign: "justify" }}>
            I dati così raccolti vengono trattati da Centro 3A per finalità di
            creazione e gestione dell’account personale, per l’attribuzione al Titolare
            dei vantaggi connessi all’utilizzo della “I AM Despar Card” secondo le
            previsioni di cui al <Link to="/tac"><u>Regolamento</u></Link> dell’Iniziativa, oltre che per finalità di
            ricezione e riscontro delle richieste d’informazione ed assistenza, nonché
            per finalità amministrative, contabili e legali connesse alla gestione del
            Programma “I AM Despar”.
        </p>
        <p style={{ textAlign: "justify" }}>
            Previo consenso dell’Utente, i dati così raccolti potranno essere
            utilizzati per finalità di marketing e, quindi, per l’invio di
            comunicazioni commerciali tramite telefono, posta cartacea e/o con modalità
            di contatto automatizzate, quali posta elettronica, sms, notifiche tramite
            app e messaggistica istantanea, ovvero per creare un profilo cliente al
            fine di inviare comunicazioni commerciali personalizzate.
        </p>
        <p style={{ textAlign: "justify" }}>
            La manifestazione di consenso per dette finalità è facoltativa e
            l’eventuale rifiuto non compromette la fruizione dei contenuti dell'App.
        </p>
        <p style={{ textAlign: "justify" }}>
            Da ultimo, i dati così raccolti potranno essere utilizzati, all’occorrenza,
            per prevenire o reprimere frodi, abusi, attività fraudolente realizzate
            tramite l'App, ovvero per accertare, esercitare o difendere i diritti di
            Centro 3A in sede di reclamo, azione risoluzione giudiziale e/o
            stragiudiziale.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.6</b> I dati personali dell’Utente possono essere trattati da
            Centro 3A a mezzo di calcolatori elettronici con utilizzo di sistemi
            software gestiti o programmati direttamente, ovvero con utilizzo di sistemi
            software gestiti da terzi, così come mediante affidamento a terzi di
            operazioni di elaborazione; Centro 3A potrà altresì creare profili relativi
            agli Utenti e, in via generale, raccogliere dati per via informatica e telematica.
        </p>
        <p style={{ textAlign: "justify" }}>
            In ogni caso, il trattamento dei dati avviene nel rispetto delle modalità
            di cui agli artt. 6 e 32 del GDPR, nonché mediante l'adozione delle
            adeguate misure di sicurezza previste.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.7</b> I dati personali dell’Utente vengono trattati unicamente da
            personale espressamente autorizzato dal Titolare del trattamento e, in
            particolare, dagli Addetti allo sviluppo ed alla gestione dell'App.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.8</b> I dati personali dell’Utente possono essere comunicati per
            una corretta gestione del rapporto a soggetti terzi quali: soggetti
            pubblici o privati per i quali la comunicazione dei dati sia obbligatoria o
            necessaria in adempimento ad obblighi di Legge o sia comunque funzionale
            all'amministrazione del rapporto, oltre agli eventuali Responsabili
            (esterni) del trattamento, debitamente nominati, il cui elenco verrà messo
            a disposizione dell’Interessato su sua semplice richiesta motivata.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>6.9</b> I dati personali dell’Utente possono essere trasferiti ai fini della
            loro conservazione in server cloud anche al di fuori del territorio UE.
            Centro 3A, nel rispetto dei principi di liceità, limitazione delle finalità
            e minimizzazione dei dati, a norma dell’art. 5 del GDPR conserva i dati
            personali dell’Utente per un periodo di tempo non superiore al
            conseguimento delle finalità per le quali sono raccolti e trattati, per
            l'esecuzione e per l'espletamento delle finalità contrattuali, per
            l’espletamento dei servizi erogati nonché nel rispetto dei tempi
            obbligatori prescritti dalla Legge.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>7</b> Autonomia delle parti.
        </p>
        <p style={{ textAlign: "justify" }}>
            Centro 3A e gli Utenti, ivi comprendendo gli Utenti Registrati, sono
            soggetti reciprocamente autonomi e indipendenti. L'utilizzo dei servizi
            offerti da Centro 3A attraverso l'App non determina l'insorgere di
            qualsivoglia diverso rapporto che non sia la fruizione di detti servizi.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>8</b> Disposizioni finali
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>8.1</b> Qualora una o più delle disposizioni di cui ai presenti Termini e
            Condizioni Generali venga dichiarata, in tutto o in parte, invalida o
            inefficace, verrà ritenuta scindibile dalle altre e non inciderà sulla
            validità e sull’efficacia degli altri Termini e Condizioni Generali, così
            come di ogni nota, avviso legale, contenuto contrattuale o anche solo
            informativo pubblicati nelle varie sezioni dell'App, ovvero richiamati
            attraverso link di collegamento, ivi comprese le informazioni relative alla
            Privacy policy.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>8.2</b> L’eventuale tolleranza, da parte di Centro 3A, di comportamenti posti
            in essere in violazione dei presenti Termini e Condizioni Generali non
            costituisce rinuncia ai diritti derivanti dalle disposizioni violate né a
            qualsivoglia diritto o facoltà scaturente da Legge o contratto.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>9</b> Legge applicabile e risoluzione delle controversie
        </p>
        <p style={{ textAlign: "justify" }}>
            Termini e Condizioni, Generali e Speciali, così come ogni contenuto dell'App
            sono regolati dalla Legge italiana. In caso di controversia, invitiamo
            l’Utente a contattare Centro 3A ai recapiti sopra indicati al fine di
            risolvere bonariamente la questione. Nell’impossibilità di una soluzione
            amichevole, sin d’ora Centro 3A manifesta l’adesione al servizio di
            conciliazione “Risolvionline”, fornito dalla Camera arbitrale di Milano, e
            meglio descritto sul sito <a href="https://www.risolvionline.com">
                <u>“www.risolvionline.com”</u></a> al quale si rinvia.
        </p>
        <p style={{ textAlign: "justify" }}>
            L’Utente, ai sensi e per gli effetti di cui agli artt. 1341 e 1342 cod.
            civ. nonché agli artt. 33, 34, 35 e 36 D. Lgs. 205/2006 (Codice del
            consumo), dichiara di aver letto e compreso e, dunque, di approvare
            espressamente il contenuto dei seguenti articoli dei presenti Termini e
            Condizioni Generali: 2. Contenuti e Condizioni di utilizzo dell'App; 3.
            Registrazione dell’Utente; 4. Interruzione dei servizi; 9. Legge
            applicabile e risoluzione delle controversie.
        </p>
        <p style={{ marginBottom: ".0001pt", background: "white" }}>
            <i>
                <span style={{ fontSize: "10.0pt" }}>
                    Ultima modifica in data 17/12/2019
                </span>
            </i>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
            &nbsp;
        </p>
    </>
);

export default AppConditions;
