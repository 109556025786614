import * as actionTypes from "./actionTypes"
import { smartlistService } from "../../../services/slists/SmartlistService"

export const favorites = (favorites) => {
    return {
        type: actionTypes.FAVORITES,
        favorites: favorites
    }
}

export const add = (favorites) => {
    return {
        type: actionTypes.ADD_FAVORITE,
        favorites: favorites
    }
}

export const remove = (favorites) => {
    return {
        type: actionTypes.DELETE_FAVORITE,
        favorites: favorites
    }
}

export const slists = (slists) => {
    return {
        type: actionTypes.SLISTS,
        slists: slists
    }
}

export const getFavorites = () => {
    return (dispatch, getState) => {
        smartlistService.getFavorites()
            .then(response => {
                dispatch(favorites(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const addFavorite = (barcode) => {
    return (dispatch, getState) => {
        smartlistService.addFavorite(barcode)
            .then(response => {
                dispatch(add(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const deleteFavorite = (barcode) => {
    return (dispatch, getState) => {
        smartlistService.deleteFavorite(barcode)
            .then(response => {
                dispatch(remove(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const getSlists = () => {
    return (dispatch, getState) => {
        smartlistService.getSlists()
            .then(response => {
                dispatch(slists(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};