import React from 'react'
import { Link } from 'react-router-dom'

export default function Tac(props) {
    return (
        <>
            <h3 style={{ textAlign: "justify", marginBottom: "20px" }}>
                Regolamento per l'utilizzo della "I AM DESPAR CARD"
            </h3>
            <p style={{ textAlign: "justify" }}>
                La carta fedeltà “I AM Despar Card” (nel seguito anche “Carta Fedeltà” o
                “Card” ) è una carta gratuita, nominativa, dedicata ai Clienti dei
                supermercati ad insegna Despar, Despar Express, Eurospar, La bottega,
                aderenti al Programma di raccolta punti “I AM Despar” (nel seguito anche
                “Programma” o “Iniziativa”), che abbiano compiuto la maggiore età, espresso
                la volontà di aderire al Programma ed abbiano prestato il consenso
                all’adesione al momento dell’attivazione della “I AM Despar Card” presso
                uno dei supermercati aderenti all’iniziativa, sul Sito
                www.desparsupermercati.it, o mediante l’App “I AM Despar Nord Ovest”.
            </p>
            <p style={{ textAlign: "justify" }}>
                L’Iniziativa è esclusa dall’ambito di applicazione del DPR 430/2001 ai
                sensi dell’art. 6, co. 1, lett. c-bis).
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>1. Soggetto Promotore.</b>
            </p>
            <p style={{ textAlign: "justify" }}>
                Promotore dell’Iniziativa “I AM Despar” è Centro 3 A S.p.A. con sede
                legale in Asti (AT), Fraz. Quarto inferiore, Strada San Bartolomeo n.276/A,
                Codice Fiscale 02457360044, Partita IVA 01206220053 (nel seguito, “Centro 3 A”
                o “Promotore”), che opera su incarico di 3 A S.c.r.l., con sede legale
                in Asti (AT), Fraz. Quarto Inferiore, Strada San Bartolomeo n.276/A.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>2. Destinatari.</b>
            </p>
            <p style={{ textAlign: "justify" }}>
                Il Programma di raccolta punti e sconti “I AM Despar”, alle condizioni di
                cui al presente Regolamento, è destinato alle sole persone fisiche, Clienti
                dei supermercati aderenti al Programma che espongono il presente Regolamento
                ed il materiale promozionale, qualificate “Consumatori” ai
                sensi dell’art. 3, co. 1, lett. a), D. Lgs. 206/2005, maggiorenni,
                residenti o domiciliati in Italia, nella repubblica di San Marino o nello
                Stato della Città del Vaticano, che accetteranno il Regolamento ed
                aderiranno all’Iniziativa (nel seguito, il “Titolare”).
            </p>
            <p style={{ textAlign: "justify" }}>
                Sono esclusi dalla partecipazione al Programma “I AM Despar” i Clienti
                che rivestano la qualità di Professionisti, intendendosi per tali le
                persone fisiche e/o giuridiche che, in relazione all'acquisto di prodotti
                presso i supermercati aderenti all’Iniziativa, agiscono nell’esercizio
                della propria attività imprenditoriale, commerciale, professionale o
                artigianale.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>3. Area di diffusione e durata dell'Iniziativa.</b>
            </p>
            <p style={{ textAlign: "justify" }}>
                La presente Iniziativa è estesa a tutti i supermercati ad insegna Despar, Despar Express, Eurospar, La bottega, presenti nelle regioni Piemonte,
                Liguria e Valle d’Aosta e nelle province di Milano, Pavia e Varese, che
                espongono il presente Regolamento ed il materiale promozionale “I AM
                Despar”.cm
                L’elenco completo dei supermercati aderenti all’Iniziativa è
                disponibile sul sito www.desparsupermercati.it
            </p>
            <p style={{ textAlign: "justify" }}>
                Il Titolare della Card potrà accumulare punti ad ogni acquisto effettuato e fruire di sconti esclusivi su specifici
                panieri di prodotti appositamente comunicati ed evidenziati presso i supermercati aderenti all'iniziativa, come
                da Regolamento consultabile sul Sito <a href="https://www.desparsupermercati.it/" target="_blank" rel="noopener noreferrer">
                    www.desparsupermercati.it</a> o sull' App I AM Despar Nord Ovest.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>4. Rilascio e attivazione della "I AM DESPAR CARD".</b>
            </p>
            <p style={{ textAlign: "justify" }}>
                La “I AM Despar Card” viene rilasciata gratuitamente, pur rimanendo di
                proprietà di Centro 3 A , su semplice richiesta del Cliente, presso tutti i
                supermercati ad insegna Despar, Despar Express, Eurospar, La bottega, che
                aderiscono all’Iniziativa.
            </p>
            <p style={{ textAlign: "justify" }}>
                Sulla “I AM Despar Card” è indicato un numero identificativo, che il
                Titolare deve utilizzare in fase di attivazione. L’attivazione della Card
                tramite registrazione dei propri dati è l’unica modalità per aderire all’Iniziativa,
                accumulare punti ed ottenere buoni spesa.
            </p>
            <p style={{ textAlign: "justify" }}>
                Per attivare la “I AM Despar Card”, il Titolare può scegliere una delle
                seguenti modalità:
            </p>
            <ul>
                <li className="mb-2">
                    presso il supermercato: il Titolare può utilizzare uno dei totem dedicati
                    all’Iniziativa, inserire il codice identificativo della propria Carta
                    Fedeltà oppure scannerizzarne il codice a barre mediante l’apposito lettore
                    e seguire le indicazioni per la registrazione;
                </li>
                <li className="mb-2">
                    dal Sito www.desparsupermercati.it: il Titolare può collegarsi al sito
                    internet direttamente dal proprio computer o smartphone, accedere
                    all’apposita sezione, inserire il codice identificativo della propria Carta
                    Fedeltà e seguire le indicazioni per la registrazione;
                </li>
                <li className="mb-2">
                    dall’App “I AM Despar Nord Ovest”: il Titolare può scaricare
                    gratuitamente l’applicazione sul proprio smartphone da Google Play o App
                    Store e, letta ed accettata la Licenza d’uso, accedere all’apposita
                    sezione, inserire il codice identificativo della propria Carta Fedeltà e
                    seguire le indicazioni per la registrazione.
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                <b>5. Registrazione, creazione di un account personale e adesione al Programma "I AM Despar".</b>
            </p>
            <p style={{ textAlign: "justify" }}>
                Per la registrazione al Programma “I AM Despar” e la creazione di un
                account personale, viene chiesto al Titolare di indicare i suoi dati
                anagrafici, di residenza o domicilio, oltre all’indirizzo e-mail e/o al
                numero di cellulare, che verranno utilizzati per ogni comunicazione
                relativa alla “I AM Despar Card” .
            </p>
            {/*<p style={{ textAlign: "justify" }}>*/}
            {/*    Per i Clienti già titolari de LaTua!Card, in sede di registrazione della*/}
            {/*    “I AM Despar Card” sarà possibile visualizzare i campi precompilati con i*/}
            {/*    dati già forniti, che devono comunque essere verificati e confermati dal*/}
            {/*    Titolare.*/}
            {/*</p>*/}
            <p style={{ textAlign: "justify" }}>
                Il Titolare si impegna e dichiara di fornire informazioni e dati veritieri
                ed aggiornati, liberando Centro 3 A da ogni e qualsivoglia responsabilità
                per il caso di dichiarazioni mendaci. È onere e responsabilità del Titolare
                provvedere al tempestivo aggiornamento delle informazioni e dei dati
                forniti. Centro 3 A, sia nella fase di registrazione sia nel seguito, potrà
                richiedere al Titolare la presentazione di un documento di identità per la
                verifica dei dati anagrafici forniti. La non veridicità dei dati comporterà
                l’annullamento della “I AM Despar Card” e la perdita dei punti sino a quel
                momento accumulati e non fruiti dal Titolare.
            </p>
            <p style={{ textAlign: "justify" }}>
                I dati forniti dal Titolare in sede di registrazione sono trattati dal
                Promotore conformemente a quanto previsto dalle norme in materia di
                protezione dei dati personali, come meglio specificato
                nell’apposita {props.isTotem ? "Informativa" : <Link to="/privacy"><u>Informativa</u>.</Link>}
            </p>
            <p style={{ textAlign: "justify" }}>
                Il Titolare è responsabile della conservazione, della protezione e della
                riservatezza delle credenziali di accesso all’account personale.
            </p>
            <p style={{ textAlign: "justify" }}>
                La registrazione comporta l’accettazione, da parte del Titolare,
                dei {props.isTotem ? "Termini e Condizioni Generali" : <Link to="/generals"><u>Termini e Condizioni Generali</u></Link>}
                di utilizzo del Sito www.desparsupermercati.it, dell’App “I AM Despar Nord Ovest” e del
                presente Regolamento.
            </p>
            <p style={{ textAlign: "justify" }}>
                La “I AM Despar Card” è nominativa, personale e non può essere ceduta a
                terzi. Ciascun Cliente può essere titolare di una sola Carta Fedeltà.
            </p>
            <p style={{ textAlign: "justify" }}>
                Il Titolare può, in qualunque momento, procedere alla cancellazione del
                proprio account personale, seguendo le indicazioni riportate nell’apposita
                sezione. La cancellazione dell’account personale comporta la cancellazione
                della “I AM Despar Card” e la perdita dei punti sino a quel momento
                accumulati e non fruiti.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>6. Utilizzo della "I AM Despar Card", modalità di accumulo dei punti, esclusioni e fruizione di sconti esclusivi e buoni spesa.</b>
            </p>
            <p>
                Per tutto il periodo di validità del presente Regolamento, il Titolare potrà:
            </p>
            <ul>
                <li className="mb-2">
                    accumulare punti ad ogni acquisto effettuato;
                </li>
                <li className="mb-2">
                    fruire di sconti esclusivi su specifici panieri di prodotti appositamente comunicati ed evidenziati;
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                presso uno dei supermercati ad insegna Despar, Despar Express, Eurospar, La
                bottega aderenti all’ Iniziativa.
            </p>
            <p style={{ textAlign: "justify" }}>
                Il Titolare deve consegnare all’addetto cassa la propria “I AM Despar Card”,
                in formato plastificato ovvero elettronico laddove utilizzi l’App “I AM
                Despar Nord Ovest”, prima dell’avvio delle operazioni di conteggio dei
                prodotti acquistati. La mancata consegna della Card nella fase precedente
                il conteggio dei prodotti non consente il calcolo e l’accumulo dei punti.
            </p>
            <p style={{ textAlign: "justify" }}>
                Non saranno accettate eventuali fotocopie o scansioni della “I AM Despar Card”.
            </p>
            <p style={{ textAlign: "justify" }}>
                I punti vengono calcolati sul totale di ogni singolo scontrino, IVA
                inclusa, al netto di eventuali resi, sconti, abbuoni di qualsivoglia
                natura, con i seguenti parametri:
            </p>
            <ul>
                <li className="mb-2">
                    1 punto ogni Euro di spesa, oltre la soglia scontrino di 5 € (ad esempio per 6 € di spesa = 6 punti)
                </li>
                <li className="mb-2">
                    2 punti ogni Euro di spesa di prodotti firmati Despar, oltre la soglia scontrino di 5 € (ad esempio per 10 € di spesa di cui 5 € di prodotti firmati Despar = 10 punti + 5 punti aggiuntivi per i prodotti Despar, per un totale di 15 punti)
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>È esclusa la possibilità di cumulare più scontrini.</p>
            <p>
                L’eventuale reso di un prodotto comporta lo storno dei punti originati dall’acquisto del prodotto reso.
            </p>
            <p style={{ textAlign: "justify" }}>
                Rientrano nel Programma e, quindi, consentono l’accumulo dei punti “I AM
                Despar”, gli acquisti di tutti gli articoli posti in vendita presso i
                supermercati a insegna Despar, Despar Express, Eurospar, La bottega
                aderenti all’Iniziativa, con espressa esclusione, ove presenti in
                assortimento, di: giornali e riviste, secondo quanto previsto dalla Legge
                n.416 del 5 agosto 1981, abbonamenti e ricariche tv, farmaci, carte sim,
                ricariche telefoniche, gift card, gratta e vinci, biglietti di lotteria,
                cofanetti regalo, costi di consegna a domicilio, costi di installazione e
                manutenzione, costi dei servizi farmaceutici, costi per estensione
                garanzie, biglietti TicketOne e, in ogni caso, tutte le categorie
                merceologiche per le quali è per legge vietata la pubblicità.
            </p>
            <p style={{ textAlign: "justify" }}>
                I punti vengono contabilizzati ed accumulati in modalità elettronica. Il
                Titolare può in ogni momento consultare il saldo dei punti accumulati
                accedendo al proprio account personale. Il totale dei punti accumulati
                viene altresì indicato sullo scontrino emesso ad ogni nuovo acquisto
                effettuato presso uno dei supermercati aderenti all’Iniziativa.
            </p>
            <p style={{ textAlign: "justify" }}>
                I punti così accumulati permettono al Titolare di ricevere un vantaggio in
                termini di buoni spesa, fruibili su di una spesa successiva effettuata
                nel medesimo o negli altri supermercati aderenti all’Iniziativa, con i
                seguenti parametri:
            </p>
            <ul>
                <li className="mb-2">
                    per 500 punti, un buono spesa di 3,00 Euro;
                </li>
                <li className="mb-2">
                    per 750 punti, un buono spesa di 5,00 Euro;
                </li>
                <li className="mb-2">
                    per 1.400 punti , un buono spesa di 10,00 Euro.
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                L’utilizzo dei buoni spesa non dà diritto a resto; i buoni spesa devono
                essere utilizzati per l’acquisto di prodotti per un valore uguale o
                superiore al buono spesa.
            </p>
            <p style={{ textAlign: "justify" }}>
                Il programma propone automaticamente al Titolare la soglia massima di buono spesa
                che può redimere in funzione dei punti accumulati e consente l’utilizzo di un solo
                buono spesa per ogni scontrino. Non si possono quindi utilizzare più buoni nella stessa spesa,
                indipendentemente dalla disponibilità dei punti cumulati.
            </p>
            <p style={{ textAlign: "justify" }}>
                I punti, così come i buoni spesa, spettano al solo Titolare della “I AM
                Despar Card” e non sono, a nessun titolo, cedibili o trasferibili.
            </p>
            <p style={{ textAlign: "justify" }}>
                I buoni spesa devono essere fruiti dal Titolare entro il termine di cui
                al precedente articolo “3. Area di diffusione e durata dell’Iniziativa”.
            </p>
            <p style={{ textAlign: "justify" }}>
                I punti ed i buoni spesa accumulati in virtù della presente Iniziativa
                non possono essere fruiti nell’ambito di diverse iniziative dello stesso o
                di diverso soggetto Promotore.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>7. Promozioni periodiche.</b>
            </p>
            <p style={{ textAlign: "justify" }}>
                Per determinati prodotti e/o in determinati periodi, potranno essere
                riconosciuti al Titolare un numero di punti aggiuntivi rispetto a quanto
                indicato al precedente articolo “6. Utilizzo della “I AM Despar Card”,
                modalità di accumulo dei punti, esclusioni e buoni spesa”. Il Titolare
                verrà adeguatamente informato dell’iniziativa con le modalità di cui al
                successivo articolo “12. Comunicazioni”.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>8. Validità della "I AM Despar Card".</b>
            </p>
            <p style={{ textAlign: "justify" }}>
                La registrazione al presente Programma ha validità dall’attivazione della “I AM Despar Card”. La Carta Fedeltà verrà automaticamente disattivata in caso di mancato utilizzo per un periodo di tempo di 30 mesi consecutivi. In caso di disattivazione della Carta Fedeltà, sarà necessario effettuare una nuova registrazione.
            </p>
            <p style={{ textAlign: "justify" }}>
                Il Promotore si riserva in ogni caso il diritto di revocare la “I AM Despar Card”,
                in ogni momento e senza alcun preavviso, per il caso in cui ne
                venga rilevato un utilizzo fraudolento od improprio, ovvero lesivo nei
                riguardi del Promotore.
            </p>
            <p style={{ textAlign: "justify" }}>
                La revoca comporta l’annullamento della “I AM Despar Card” e la perdita dei
                punti sino a quel momento accumulati e non fruiti. Il Promotore si riserva
                il diritto di richiedere la restituzione di eventuali benefici, quali
                punti o buoni spesa, acquisiti illecitamente, nonché di intraprendere ogni
                opportuna azione.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>9. Smarrimento o furto della "I AM Despar Card".</b>
            </p>
            <p>
                Per il caso di smarrimento o furto della “I AM Despar Card”, il Titolare
                si impegna a fornire tempestiva comunicazione presso uno dei supermercati
                aderenti all’Iniziativa, ovvero con le modalità di cui al successivo
                articolo “12. Comunicazioni”.
            </p>
            <p>
                Centro 3 A declina qualsivoglia responsabilità per l’eventuale utilizzo
                improprio della “I AM Despar Card” sino al momento della comunicazione
                dello smarrimento o del furto da parte del Titolare. Il Titolare riceverà
                una nuova Carta Fedeltà, con un nuovo codice identificativo, che dovrà
                attivare con le modalità di cui ai precedenti articoli <br /> “4. Rilascio e
                attivazione di “I AM Despar Card” e “5. Registrazione, creazione di un
                account personale e adesione al Programma I AM Despar ”.
            </p>
            <p>
                L’attivazione della nuova “I AM Despar Card” consentirà al Titolare di
                recuperare e conservare i punti accumulati con la Carta Fedeltà
                precedente, andata smarrita ovvero sottratta.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>10. Interruzione dei servizi, malfunzionamenti.</b>
            </p>
            <p>
                Il Promotore potrà sospendere temporaneamente l’accesso al Sito
                www.desparsupermarcati.it o all’App “I AM Despar Nord Ovest” in caso di
                problemi tecnici o di esigenze manutentive, così come potrà modificarne
                impostazioni e contenuti. Il Promotore potrà altresì cessare in qualunque
                momento, in tutto o in parte, la prestazione dei servizi resi a mezzo del
                Sito e/o dell’App, fatto salvo il rispetto degli impegni assunti con il
                presente Regolamento.
            </p>
            <p>
                Il Promotore non si assume alcuna responsabilità per eventuali difficoltà,
                disfunzioni o impedimenti di accesso all’account personale del Titolare
                cagionati dagli strumenti tecnici, dalla rete di connessione o qualsivoglia
                causa non dipendente dal fatto del Promotore.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>11. Modifiche, invalidità o inefficacia del Regolamento.</b>
            </p>
            <p>
                Il Promotore si riserva la facoltà di apportare in ogni momento modifiche
                al presente Regolamento. Le modifiche avranno efficacia dal momento della
                comunicazione al Titolare con le modalità di cui al successivo articolo
                “12. Comunicazioni”.
            </p>
            <p>
                Qualora una o più delle disposizioni di cui al presente Regolamento venga
                dichiarata, in tutto o in parte, invalida o inefficace, verrà ritenuta
                scindibile dalle altre e non inciderà sulla validità e sull’efficacia delle
                altre disposizioni. L’eventuale tolleranza, da parte del Promotore, di
                comportamenti posti in essere dal Titolare in violazione del presente
                Regolamento, non costituisce rinuncia ai diritti derivanti dalle
                disposizioni violate né a qualsivoglia diritto o facoltà scaturente da
                Legge o contratto.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>12. Comunicazioni.</b>
            </p>
            <p>
                Le comunicazioni di carattere generale potranno essere esposte nei
                supermercati aderenti all’iniziativa, pubblicate sul Sito
                www.desparsupermercati.it e mediante l’App “I AM Despar Nord Ovest” e
                s’intenderanno efficaci dal momento della loro esposizione e/o pubblicazione.
            </p>
            <p>
                Le comunicazioni destinate al Titolare ai sensi e per gli effetti di cui al
                presente Regolamento verranno inviate a mezzo e-mail, all’indirizzo
                indicato, o comunicate a mezzo sms, al numero di cellulare indicato, e
                s’intenderanno efficaci dal momento della loro comunicazione.
            </p>
            <p>
                Le comunicazioni destinate al Promotore ai sensi e per gli effetti di cui
                al presente Regolamento dovranno essere indirizzate a Centro 3 A S.p.A.,
                Strada San Bartolomeo n.276/A, Fraz. Quarto Inferiore 14030 Asti (AT)
                ovvero all’indirizzo PEC centro3aspa@pec.it
                o all’indirizzo e-mail marketing@centro3a.it e s’intenderanno efficaci
                dal momento della loro ricezione.
            </p>
            <p style={{ textAlign: "justify" }}>
                <b>13. Legge applicabile e risoluzione delle controversie.</b>
            </p>
            <p>
                I termini e le condizioni di cui al presente Regolamento sono regolati
                dalla Legge italiana.
            </p>
            <p>
                In caso di controversia, si invita il Titolare a contattare il Promotore ai
                recapiti indicati al precedente articolo “12. Comunicazioni” al fine di
                risolvere bonariamente la questione. Nell’impossibilità di una soluzione
                amichevole, sin d’ora il Promotore ed il Titolare manifestano l’adesione al
                servizio di conciliazione “Risolvionline”, fornito dalla Camera Arbitrale
                di Milano, e meglio descritto sul sito “www.risolvionline.com” al quale si rinvia.
            </p>
            {/*
            <p>
                <em>[accettazione]</em>
            </p>
            <p align="center">
                ***
            </p>
             */}
            <p>
                Il Titolare, ai sensi e per gli effetti di cui agli artt. 1341 e 1342 cod.
                civ. nonché agli artt. 33, 34, 35 e 36 D. Lgs. 205/2006 (Codice del
                Consumo), dichiara di aver letto e compreso e, dunque, di approvare
                espressamente il contenuto dei seguenti articoli del presente Regolamento:
                2. Destinatari; 5. Registrazione, creazione di un account personale e
                adesione al programma “I AM Despar” ; 6. Utilizzo della “I AM Despar Card”,
                modalità di accumulo dei punti, esclusioni e fruizione dei buoni spesa;
                8. Validità; 13. Legge applicabile e risoluzione delle controversie.
            </p>
        </>
    )
}
