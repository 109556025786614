import React from "react"
import useForm from 'react-hook-form'
import { Translation } from 'react-i18next';
import passwordChange from "./Service"
import swal from "../../../utils/swal"

function PasswordChange(props) {

    const { register, handleSubmit, watch, errors } = useForm({
        mode: 'onBlur',
    });

    const changePassword = data => {
        passwordChange(data.oldPassword, data.newPassword)
            .then(response => {
                swal.success("Password aggiornata correttamente");
            }).catch((error) => {
                swal.error(error.message);
            })
    }

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(changePassword)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="oldPassword">{t('oldPassword')}*</label>
                            <input type="password" className="form-control" id="oldPassword" name="oldPassword"
                                ref={register({
                                    required: "Campo obbligatorio",
                                    minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                })}
                                aria-describedby="oldPasswordHelp" placeholder="" />
                            <div className="form-control-invalid">
                                {errors.oldPassword && errors.oldPassword.message}
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="newPassword">{t('newPassword')}*</label>
                            <input type="password" className="form-control" id="newPassword" name="newPassword"
                                ref={register({
                                    required: "Campo obbligatorio",
                                    minLength: { value: 8, message: "La password deve essere lunga almeno 8 caratteri" }
                                })}
                                aria-describedby="newPasswordHelp" placeholder="" />
                            <div className="form-control-invalid">
                                {errors.newPassword && errors.newPassword.message}
                            </div>
                            <small id="newPasswordHelp" className="form-text text-muted">
                                La password deve essere lunga almeno 8 caratteri e contenere
                            almeno tre tra: lettere minuscole, maiuscole, numeri e simboli</small>
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="passwordConfirm">{t('passwordConfirm')}*</label>
                            <input type="password" className="form-control" id="passwordConfirm" name="passwordConfirm"
                                ref={register({
                                    required: "Campo obbligatorio",
                                    validate: {
                                        passwordMatch: value => value === watch('newPassword')
                                    }
                                })}
                                aria-describedby="passwordConfirmHelp" placeholder="" />
                            <div className="form-control-invalid">
                                {errors.passwordConfirm && errors.passwordConfirm.message}
                                {errors.passwordConfirm && errors.passwordConfirm.type === "passwordMatch" && (
                                    <span>Le password non coincidono</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 mt-3 mb-3">
                            <button type="submit" className="btn btn-success btn-lg btn-block">
                                {t("update")}
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Translation>
    );
}

export default PasswordChange;



