import CustomError from "./customError";

export function handleResponse(response) {
    if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            localStorage.removeItem("user")
            throw new CustomError(response.status, "Errore di comunicazione col server");
        }

        if ([400].indexOf(response.status) !== -1) {
            return Promise.reject("Errore di comunicazione col server");
        }
    }
    return response.data;
}

