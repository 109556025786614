import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Logo from "../../../images/logo_despar.png"
import { LinkContainer } from "react-router-bootstrap";
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class SbNavbar extends Component {
    render() {
        return (
            // <Container fluid={true}>
            <Translation>
                {t =>
                    <Navbar collapseOnSelect bg="light" expand="lg">
                        <LinkContainer to="/">
                            <Navbar.Brand href="/">
                                <img src={Logo} style={logoStyle} alt='logo' />
                            </Navbar.Brand>
                        </LinkContainer>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                {this.props.user && <LinkContainer to="/profile"><Nav.Link href="/profile">{t("profile")}</Nav.Link></LinkContainer>}
                                {this.props.user && <LinkContainer to="/card"><Nav.Link href="/card">{t("card")}</Nav.Link></LinkContainer>}
                                <LinkContainer to="/stores"><Nav.Link href="/stores">{t("stores")}</Nav.Link></LinkContainer>
                                <LinkContainer to="/leaflets"><Nav.Link href="/leaflets">{t("leaflets")}</Nav.Link></LinkContainer>
                            </Nav>

                            <Nav>
                                {this.props.user && <LinkContainer onClick={() => this.props.logout(this.props.history)} to="#"><Nav.Link to="#">{t("logout")}</Nav.Link></LinkContainer>}
                                {!this.props.user && <LinkContainer to="/login"><Nav.Link to="/login">{t("access")}</Nav.Link></LinkContainer>}
                                {!this.props.user && <LinkContainer to="/signup"><Nav.Link to="/login">{t("sign_in")}</Nav.Link></LinkContainer>}
                                <Nav.Link href="http://www.desparsupermercati.it">Torna al sito</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                }
            </Translation>
            // </Container>
        )
    }
}

const logoStyle = {
    height: 'auto',
    width: '200px',

}

export default withRouter(SbNavbar);
