import React from "react";
import { storesService } from "../../services/basis/StoresService"
import { Translation } from "react-i18next";

export default class ProfileAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counties: []
        };
    }

    componentDidMount() {
        storesService.counties().then(response => {
            this.setState({
                counties: response
            })
        });
    }

    render() {
        const { counties } = this.state;
        const { register, errors, user, formData } = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="form-row">
                            <input type="hidden" id="addressId" name="addressId" ref={register}
                                defaultValue={user && user.address && user.address.id} />

                            <div className="form-group col-md-6">
                                <label htmlFor="city">{t('city')}*</label>
                                <input type="email" className="form-control form-control-lg input-required" id="city" name="city"
                                    // eslint-disable-next-line
                                    defaultValue={user && user.address && user.address.city || formData && formData.city}
                                    ref={register({
                                        required: "Campo obbligatorio",
                                    })}
                                    aria-describedby="cityHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.city && errors.city.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="address">{t('address')}*</label>
                                <input type="email" className="form-control form-control-lg input-required" id="address" name="address"
                                    // eslint-disable-next-line
                                    defaultValue={user && user.address && user.address.address || formData && formData.address}
                                    ref={register({
                                        required: "Campo obbligatorio",
                                    })}
                                    aria-describedby="mobilePhoneHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.address && errors.address.message}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="zip">{t('zipCode')}*</label>
                                <input type="number" className="form-control form-control-lg input-required" id="zip" name="zip"
                                    // eslint-disable-next-line
                                    defaultValue={user && user.address && user.address.zip || formData && formData.zip}
                                    ref={register({
                                        required: "Campo obbligatorio"
                                    })}
                                    aria-describedby="zipHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.zip && errors.zip.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="county">{t('county')}*</label>
                                <select className="form-control form-control-lg input-required" id="county" name="county"
                                    // value={user && user.address && user.address.county}
                                    ref={register({ required: "Campo obbligatorio" })}>
                                    {counties && counties.map(item => (
                                        <option key={item.id}
                                            value={item.countyId}
                                            selected={user && user.address && user.address.county === item.countyId ? true : false
                                            // eslint-disable-next-line
                                            || formData && formData.county === item.countyId ? true : false}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-control-invalid">
                                    {errors.county && errors.county.message}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}