import React from "react";
import { Translation } from "react-i18next";
import preferences from "./Service";

export default class ProfilePreferences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profession: [],
            qualification: [],
            familySituation: [],
            animals: [],
            interests: []
        };
    }

    componentDidMount() {
        preferences().then(response => {
            this.setState({
                profession: response.data.profession.values,
                qualification: response.data.qualification.values,
                familySituation: response.data.familySituation.values,
                animals: response.data.animals.values,
                interests: response.data.interests.values,
            })
        });
    }

    render() {
        const { register, errors, user, watch } = this.props;
        const { profession, qualification, familySituation, animals, interests } = this.state;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="profession">{t('profile.profession')}</label>
                                <select className="form-control form-control-lg" id="profession" name="profession"
                                    // value={user && user.preferences && user.preferences.profession}
                                    ref={register}>
                                    <option value="">{t("profile.preferences-null")}</option>
                                    {profession && profession.map(item => {
                                        const key = Object.keys(item)[0];
                                        return (
                                            <option key={key} value={key} 
                                            selected={user && user.preferences && user.preferences.profession === key ? true : false}>
                                                {item[key]}
                                            </option>
                                        );
                                    })}
                                    {/* 
                                    <option value="EMPLOYEE">{t("preferences.profession.EMPLOYEE")}</option>
                                    <option value="SELF_EMPLOYED">{t("preferences.profession.SELF_EMPLOYED")}</option>
                                    <option value="RETIRED">{t("preferences.profession.RETIRED")}</option>
                                    <option value="UNEMPLOYED">{t("preferences.profession.UNEMPLOYED")}</option> 
                                    */}
                                </select>
                                <div className="form-control-invalid">
                                    {errors.profession && errors.profession.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="qualification">{t('profile.qualification')}</label>
                                <select className="form-control form-control-lg" id="qualification" name="qualification"
                                    // value={user && user.preferences && user.preferences.qualification}
                                    ref={register}>
                                    <option value="">{t("profile.preferences-null")}</option>
                                    {qualification && qualification.map(item => {
                                        const key = Object.keys(item)[0];
                                        return (
                                            <option key={key} value={key}
                                            selected={user && user.preferences && user.preferences.qualification === key ? true : false}>
                                                {item[key]}
                                            </option>
                                        );
                                    })}
                                    {/*
                                    <option value="SECONDARY_SCHOOL">{t("preferences.qualification.SECONDARY_SCHOOL")}</option>
                                    <option value="HIGH_SCHOOL">{t("preferences.qualification.HIGH_SCHOOL")}</option>
                                    <option value="UNIVERSITY">{t("preferences.qualification.UNIVERSITY")}</option>
                                     */}
                                </select>
                                <div className="form-control-invalid">
                                    {errors.qualification && errors.qualification.message}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="interests">{t('profile.interests')}</label>
                                <select className="form-control form-control-lg" id="interests" name="interests"
                                    // value={user && user.preferences && user.preferences.interests}
                                    ref={register}>
                                    <option value="">{t("profile.preferences-null")}</option>
                                    {interests && interests.map(item => {
                                        const key = Object.keys(item)[0];
                                        return (
                                            <option key={key} value={key}
                                            selected={user && user.preferences && user.preferences.interests === key ? true : false}>
                                                {item[key]}
                                            </option>
                                        );
                                    })}
                                    {/*
                                    <option value="COOKING">{t("preferences.interests.COOKING")}</option>
                                    <option value="GARDENING">{t("preferences.interests.GARDENING")}</option>
                                    <option value="DIY">{t("preferences.interests.DIY")}</option>
                                    <option value="TRAVELS">{t("preferences.interests.TRAVELS")}</option>
                                    <option value="SPORTS">{t("preferences.interests.SPORTS")}</option>
                                    <option value="BOOKS">{t("preferences.interests.BOOKS")}</option>
                                    <option value="FASHION">{t("preferences.interests.FASHION")}</option>
                                     */}
                                </select>
                                <div className="form-control-invalid">
                                    {errors.interests && errors.interests.message}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="animals">{t('profile.animals')}</label>
                                <select className="form-control form-control-lg" id="animals" name="animals"
                                    // value={user && user.preferences && user.preferences.animals}
                                    ref={register}>
                                    <option value="">{t("profile.preferences-null")}</option>
                                    {animals && animals.map(item => {
                                        const key = Object.keys(item)[0];
                                        return (
                                            <option key={key} value={key}
                                            selected={user && user.preferences && user.preferences.animals === key ? true : false}>
                                                {item[key]}
                                            </option>
                                        );
                                    })}
                                    {/*
                                    <option value="NONE">{t("preferences.animals.NONE")}</option>
                                    <option value="DOG">{t("preferences.animals.DOG")}</option>
                                    <option value="CAT">{t("preferences.animals.CAT")}</option>
                                    <option value="OTHER">{t("preferences.animals.OTHER")}</option>
                                     */}
                                </select>
                                <div className="form-control-invalid">
                                    {errors.animals && errors.animals.message}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="familySituation">{t('profile.familySituation')}</label>
                                <select className="form-control form-control-lg" id="familySituation" name="familySituation"
                                    // value={user && user.preferences && user.preferences.familySituation}
                                    ref={register}>
                                    <option value="">{t("profile.preferences-null")}</option>
                                    {familySituation && familySituation.map(item => {
                                        const key = Object.keys(item)[0];
                                        return (
                                            <option key={key} value={key}
                                            selected={user && user.preferences && user.preferences.familySituation === key ? true : false}>
                                                {item[key]}
                                            </option>
                                        );
                                    })}
                                    {/*
                                    <option value="SINGLE">{t("preferences.familySituation.SINGLE")}</option>
                                    <option value="FAMILY_WITH_CHILDREN">{t("preferences.familySituation.FAMILY_WITH_CHILDREN")}</option>
                                    <option value="FAMILY_WITHOUT_CHILDREN">{t("preferences.familySituation.FAMILY_WITHOUT_CHILDREN")}</option>
                                     */}
                                </select>
                                <div className="form-control-invalid">
                                    {errors.familySituation && errors.familySituation.message}
                                </div>
                            </div>
                        </div>

                        {watch("familySituation") === "FAMILY_WITH_CHILDREN" &&
                            <>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="birthYearChild1">{t('profile.birthYearChild1')}</label>
                                        <input type="number" placeholder="YYYY" className="form-control form-control-lg" id="birthYearChild1" name="birthYearChild1"
                                            defaultValue={user && user.preferences && user.preferences.birthYearChild1}
                                            ref={register({
                                                minLength: { value: 4, message: "Anno di nascita non valido" },
                                                maxLength: { value: 4, message: "Anno di nascita non valido" },
                                            })} />
                                        <div className="form-control-invalid">
                                            {errors.birthYearChild1 && errors.birthYearChild1.message}
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label htmlFor="birthYearChild2">{t('profile.birthYearChild2')}</label>
                                        <input type="number" placeholder="YYYY" className="form-control form-control-lg" id="birthYearChild2" name="birthYearChild2"
                                            defaultValue={user && user.preferences && user.preferences.birthYearChild2}
                                            ref={register({
                                                minLength: { value: 4, message: "Anno di nascita non valido" },
                                                maxLength: { value: 4, message: "Anno di nascita non valido" },
                                            })} />
                                        <div className="form-control-invalid">
                                            {errors.birthYearChild2 && errors.birthYearChild2.message}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="birthYearChild3">{t('profile.birthYearChild3')}</label>
                                        <input type="number" placeholder="YYYY" className="form-control form-control-lg" id="birthYearChild3" name="birthYearChild3"
                                            defaultValue={user && user.preferences && user.preferences.birthYearChild3}
                                            ref={register({
                                                minLength: { value: 4, message: "Anno di nascita non valido" },
                                                maxLength: { value: 4, message: "Anno di nascita non valido" },
                                            })} />
                                        <div className="form-control-invalid">
                                            {errors.birthYearChild3 && errors.birthYearChild3.message}
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label htmlFor="birthYearChild4">{t('profile.birthYearChild4')}</label>
                                        <input type="number" placeholder="YYYY" className="form-control form-control-lg" id="birthYearChild4" name="birthYearChild4"
                                            defaultValue={user && user.preferences && user.preferences.birthYearChild4}
                                            ref={register({
                                                minLength: { value: 4, message: "Anno di nascita non valido" },
                                                maxLength: { value: 4, message: "Anno di nascita non valido" },
                                            })} />
                                        <div className="form-control-invalid">
                                            {errors.birthYearChild4 && errors.birthYearChild4.message}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </Translation>
        );
    }
}