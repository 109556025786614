import React from 'react'
import Barcode from "react-barcode"
import CardImage from "../../../images/card-fidelity-transparent.png"
import CardImageBack from "../../../images/card-fidelity-back.png"
import DisabledCardImage from "../../../images/despar_fidelity_disabled_new_front.png"
import DisabledCardImageBack from "../../../images/despar_fidelity_disabled_new_back.png"
import { Translation } from 'react-i18next'

export default (props) => (
    <>
        <Translation>
            {t =>
                <div className="flip-box">
                    <div className="flip-box-inner">
                        <div className="flip-box-front">
                            <img src={props.enabled ? CardImage : DisabledCardImage} alt="" style={imageStyle} />
                        </div>
                        <div className="flip-box-back">
                            <div style={container}>
                                <img src={props.enabled ? CardImageBack : DisabledCardImageBack} alt="" style={imageStyle} />
                                <div className="barcode" style={props.enabled ? barcodeStyle : disabledBarcodeStyle}>
                                    <Barcode
                                        value={props.code}
                                        format="EAN13"
                                        width={props.enabled ? 3.5 : 3.8}
                                        height={80}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    </>
);

const container = {
    width: "inherit",
    position: "relative",
}

const imageStyle = {
    width: "inherit",
    height: "auto"
}

const barcodeStyle = {
    position: "absolute",
    bottom: "17.5%",
    right: "10%",
}

const disabledBarcodeStyle = {
    position: "absolute",
    bottom: "12.5%",
    right: "7.5%",
}


