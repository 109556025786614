import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { Translation } from "react-i18next";

export default class StoreCard extends React.Component {
    render() {
        const { item, selectStore } = this.props;
        return (
            <Translation>
                {t =>
                    <div id={item.id} className="card w-100 mb-3"
                        style={{ cursor: "pointer" }} onClick={() => { selectStore(item) }}>
                        <div className="row no-gutters">
                            <div className="col-md">
                                <div className="card-body" style={cardBodyStyle}>
                                    <h6 className="card-title title" style={cardTitleStyle}>
                                        {item.name}
                                    </h6>
                                    <div className="card-text">
                                        <div>
                                            <img className="card-image img-fluid" style={logoStyle}
                                                src={item.logoImageUrl ? item.logoImageUrl : Placeholder} alt=""
                                                onError={(e) => { e.target.src = Placeholder }} />
                                        </div>
                                        <small style={storeCardTextStyle}>
                                            {item.address && item.address.address}<br />
                                            {item.address && item.address.zip} - {item.address && item.address.city} ({item.address && item.address.county})
                                        </small>
                                    </div>
                                    {/* <p className="card-text-muted">
                                        {item.phone && item.phone} {item.email && `- ${item.email}`}
                                    </p> */}
                                    {item.distance &&
                                        <div className="text-muted">
                                            {t("distance")}: {`${item.distance} Km`}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const cardBodyStyle = {
    padding: "5px 10px"
}

const cardTitleStyle = {
    marginBottom: "5px"
}

const storeCardTextStyle = {
    lineHeight: '0'
}

const logoStyle = {
    width: '200px',
    height: 'auto'
}