import * as actionTypes from "./actionTypes"
import { authenticationService } from "../../../services/basis/AuthenticationService"
import { history } from "../../../App"
import swal from "../../../utils/swal"
import toaster from "../../../utils/toaster"

export const loginAction = (user) => {
    return {
        type: actionTypes.LOGIN,
        user: user?.profile,
        jwt: user?.jwt
    }
}

export const login = (data, isDigi) => {
    return dispatch => {
        authenticationService.login(data)
            .then(response => {
                dispatch(loginAction(response.data))
                if (history.location.pathname.indexOf("/totem") > -1) {
                    if (isDigi) {
                        history.push("/totem/upgrade")
                    } else {
                        history.push("/totem/card")
                    }
                } else {
                    history.push("/card")
                }
            }).catch((error) => {
                dispatch(loginAction(null))
                toaster.error("Credenziali errate")
            })
    }
};

export const logoutAction = () => {
    return {
        type: actionTypes.LOGOUT,
        user: null
    }
}

export const logout = () => {
    return dispatch => {
        if (history.location.pathname.indexOf("/totem") > -1) {
            history.push("/totem")
        } else {
            history.push("/")
        }
        dispatch(logoutAction())
        authenticationService.logout()
            .then(response => {
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const updateUserAction = (user) => {
    return {
        type: actionTypes.USER_UPDATE,
        user: user
    }
}

export const updateUser = (data) => {
    return dispatch => {
        authenticationService.updateUser(data)
            .then(response => {
                swal.success('Profilo aggiornato con successo');
                dispatch(updateUserAction(response.data))
            }).catch((error) => {
                swal.error('Errore durante l\'aggiornamento del profilo');
                console.log(error);
            })
    }
};

export const updateTotemUser = (data) => {
    return dispatch => {
        authenticationService.updateUser(data)
            .then(response => {
                swal.success('Profilo aggiornato con successo');
                dispatch(updateUserAction(response.data))
                history.push("/totem")
                dispatch(logoutAction())
                authenticationService.logout()
                    .then(response => {
                    }).catch((error) => {
                        console.log(error);
                    })
            }).catch((error) => {
                swal.error('Errore durante l\'aggiornamento del profilo');
                console.log(error);
            })
    }
};

export const deleteUserAction = () => {
    return {
        type: actionTypes.USER_DELETE,
        user: null
    }
}

export const deleteUser = (id) => {
    return dispatch => {
        authenticationService.deleteUser(id)
            .then(response => {
                swal.success('Profilo eliminato con successo');
                dispatch(deleteUserAction())
                if (history.location.pathname.indexOf("/totem") > -1) {
                    history.push("/totem")
                } else {
                    history.push("/")
                }
                dispatch(logoutAction())
                authenticationService.logout()
                    .then(response => {
                    }).catch((error) => {
                        console.log(error);
                    })
            }).catch((error) => {
                swal.error('Errore durante la cancellazione del profilo');
                console.log(error);
            })
    }
};

export const isLoggedAction = (user) => {
    return {
        type: actionTypes.IS_LOGGED,
        user: user
    }
}

export const isLogged = () => {
    return dispatch => {
        authenticationService.isLogged()
            .then(response => {
                dispatch(isLoggedAction(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const cardDisableAction = (card) => {
    return {
        type: actionTypes.CARD_DISABLE,
        card: card
    }
}

export const cardDisable = (id) => {
    return dispatch => {
        authenticationService.disableCard(id)
            .then(response => {
                console.log(response);
                dispatch(cardDisableAction(response.data[0]))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const coordinateAction = (latitude, longitude) => {
    return {
        type: actionTypes.COORDINATE,
        latitude: latitude,
        longitude: longitude
    }
}

export const coordinate = (latitude, longitude) => {
    return dispatch => {
        dispatch(coordinateAction(latitude, longitude))
    }
};