import React from 'react'
import useForm from 'react-hook-form'
import { Translation } from 'react-i18next';
import resetPassword from "./Service"
import swal from "../../utils/swal"

export default function PasswordReset(props) {

    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        resetPassword(data.email)
            .then(response => {
                swal.success("Richiesta effettuata con successo",
                "A breve riceverai una email per completare il processo di cambio password.");
            }).catch((error) => {
                swal.error(error.message);
            })
    }


    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                    <div className="card">
                        <h3 className="card-header">{t("passwordReset.title")}</h3>
                        <div className="card-body">
                            <div className="form-group col-md-8 col-md-offset-2">
                                <label htmlFor="email">{t('passwordReset.subtitle')}</label>
                                <input type="email" className="form-control" id="email" name="email"
                                    ref={register({
                                        required: "Campo obbligatorio",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Indirizzo email non valido"
                                        }
                                    })}
                                    aria-describedby="emailHelp" placeholder="" />
                                <div className="form-control-invalid">
                                    {errors.email && errors.email.message}
                                </div>
                                <small id="emailHelp" className="form-text text-muted">
                                    {t('passwordReset.help')}
                                </small>
                                <button className="mt-3 btn btn-lg btn-primary" type="submit">
                                    {t("send")}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </Translation>
    );

}
