import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import axios from 'axios';
import { authHeader } from '../../utils/authHeader';

export const authenticationService = { login, logout, signup, isLogged, laTua, updateUser, deleteUser, disableCard, upgrade };

function login(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/login${data.recaptcha ? `?g-recaptcha-response=${data.recaptcha}` : ""}`,
            data
        )
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user")
    return (
        axios.post(`${config.baseUrl}/cli/profiles/logout`,
            {},
            { headers: authHeader() })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function signup(data) {
    let body = {
        username: data.email,
        password: data.password,
        terminalType: data.terminalType,
        profile: {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            mobilePhone: data.mobilePhone,
            email: data.email,
            // ssnCode: data.ssnCode,
            gender: data.gender,
            cards: [{
                code: data.cardCode,
                enabled: true
            }],
            address: {
                county: data.county,
                city: data.city,
                zip: data.zip,
                address: data.address
            },
            preferences: {
                birthYearChild1: data.birthYearChild1,
                birthYearChild2: data.birthYearChild2,
                birthYearChild3: data.birthYearChild3,
                birthYearChild4: data.birthYearChild4,
                familySituation: data.familySituation !== "" ? data.familySituation : null,
                interests: data.interests !== "" ? data.interests : null,
                animals: data.animals !== "" ? data.animals : null,
                qualification: data.qualification !== "" ? data.qualification : null,
                profession: data.profession !== "" ? data.profession : null,
            },
            accept1: data.accept1,
            accept2: data.accept2,
            accept3: data.accept3,
            accept4: data.accept4,
            accept5: data.accept5,
            accept6: data.accept6,
            attrib1: data.attrib1,
            attrib2: data.attrib2,
            attrib3: data.attrib3,
            employee: false,
            signature: data.signature
        },
    }

    return (
        axios.post(`${config.baseUrl}/cli/profiles/signup`, body)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function isLogged() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/login`, {
            headers: authHeader()
        })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                return responseData;
            })
    );
}

function laTua(cardCode, lastName) {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/latua?cardCode=${cardCode}&lastName=${lastName}`)
            .then(handleResponse)
            .then(checkResponse)
    );
}

function updateUser(data) {
    return (
        axios.put(`${config.baseUrl}/cli/profiles`, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            mobilePhone: data.mobilePhone,
            birthDate: data.birthDate,
            ssnCode: data.ssnCode,
            accept1: data.accept1,
            accept2: data.accept2,
            accept3: data.accept3,
            accept4: data.accept4,
            accept5: data.accept5,
            accept6: data.accept6,
            gender: data.gender,
            address: {
                id: data.addressId,
                county: data.county,
                city: data.city,
                zip: data.zip,
                address: data.address
            },
            preferences: {
                birthYearChild1: data.birthYearChild1,
                birthYearChild2: data.birthYearChild2,
                birthYearChild3: data.birthYearChild3,
                birthYearChild4: data.birthYearChild4,
                familySituation: data.familySituation !== "" ? data.familySituation : null,
                interests: data.interests !== "" ? data.interests : null,
                animals: data.animals !== "" ? data.animals : null,
                qualification: data.qualification !== "" ? data.qualification : null,
                profession: data.profession !== "" ? data.profession : null,
            },
        }
            ,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function deleteUser(id) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/delete`, {
            profileId: id
        },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function disableCard(id) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/cards/${id}/disable`, {},
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function upgrade(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/upgrade`, {
            "email": data.username,
            "newPassword": data.password,
            accept3: data.accept3,
            accept4: data.accept4,
            accept5: data.accept5,
            accept6: data.accept6
        },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}