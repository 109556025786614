import React from "react";
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux"
import Footer from "../Footer/Footer";
import Home from '../../../features/Home/Home';
import Signup from "../../../features/Signup/Signup"
import Stores from "../../../features/Stores/Stores"
import Profile from '../../../features/Profile/Profile';
import Card from '../../../features/Card/Card';
import Tac from '../../../pages/Tac';
import Privacy from '../../../pages/Privacy';
import Support from '../../../pages/Support';
import Leaflets from '../../../features/Leaflets/Leaflets';
import Leaflet from "../../../features/Leaflet/Leaflet";
import Login from '../../../features/Login/Login';
import SideMenu from "../Header/SideMenu";
import * as actionCreators from "../../../redux/store/actions/index"
import Header from "../Header/Header";
import PasswordReset from "../../../features/PasswordReset/PasswordReset";
import qs from "query-string";
import config from "../../../config/config";
import GeneralConditions from "../../../pages/GeneralConditions";
import AppConditions from "../../../pages/AppConditions";
import Cookies from "../../../pages/Cookies";
import Delete from "../../../features/Delete/Delete";

class MainTemplate extends React.Component {
    render() {
        const { user, logout } = this.props;
        const origin = qs.parse(this.props.location.search).origin;
        return (
            <React.Fragment>
                {((!this.props.location.state || !this.props.location.state.origin)
                    && !(origin === config.PLATFORM_ANDROID) && !(origin === config.PLATFORM_IOS))
                    ? <Header user={user} logout={logout} /> : <div className="mb-3"></div>
                }
                <div className="container">
                    <div className="row">
                    {window.location.pathname !== "/app/tutorial-account-delete" && 
                        <div className="side-menu col-md-3 d-none d-sm-none d-md-block">
                            <SideMenu user={user} logout={logout} />
                        </div>
                    }

                        <div className={!window.location.pathname !== "/app/tutorial-account-delete" ? "content col-md-9 col-sm-12": "content"}>
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/stores' component={Stores} />
                                <Route exact path='/card' component={Card} />
                                <Route exact path='/tac' component={Tac} />
                                <Route exact path='/privacy' component={Privacy} />
                                <Route exact path='/generals' component={GeneralConditions} />
                                <Route exact path='/conditions' component={AppConditions} />
                                <Route exact path='/cookies' component={Cookies} />
                                <Route exact path='/support' component={Support} />
                                <Route exact path='/login' component={Login} />
                                <Route exact path='/reset' component={PasswordReset} />
                                <Route exact path='/signup' component={Signup} />
                                <Route exact path='/profile' component={Profile} />
                                <Route exact path='/leaflets' component={Leaflets} />
                                <Route exact path='/leaflet' component={Leaflet} />
                                <Route exact path='/tutorial-account-delete' component={Delete} />
                            </Switch>
                        </div>
                    </div>
                </div>
                {((!this.props.location.state || !this.props.location.state.origin)
                    && !(origin === config.PLATFORM_ANDROID) && !(origin === config.PLATFORM_IOS))
                    ? <Footer /> : <div></div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: (history) => dispatch(actionCreators.logout(history)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainTemplate);