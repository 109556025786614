import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from "axios";
import { authHeader } from "../../../utils/authHeader";

export default function changePassword(oldPassword, newPassword) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/password/change`, {
            oldPassword: oldPassword,
            newPassword: newPassword
        },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
    );
}