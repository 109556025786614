import React from 'react'

const Cookies = () => (
    <>
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
            Cookies Policy
        </h3>
        <p style={{ textAlign: "justify" }}>
            <b>Cos'è un Cookie</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            Un cookie è un breve testo inviato al tuo browser da un sito web visitato. Consente al sito di memorizzare informazioni
            sulla tua visita, come la tua lingua preferita ed altre impostazioni. Ciò può facilitare la tua visita successiva ed
            aumentare l'utilità del sito a tuo favore. I cookie svolgono un ruolo importante.
            Senza di essi, l'utilizzo del Web sarebbe un'esperienza molto più frustrante.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>Come li utilizziamo</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            I cookie da noi utilizzati sono esclusivamente quelli di natura tecnica, che hanno lo scopo:
        </p>
        <ul>
            <li className="mb-2">
                di facilitare agli utenti l’accesso ai servizi offerti dal sito;
            </li>
            <li className="mb-2">
                di agevolare la navigazione all’interno dello stesso.
            </li>
        </ul>
        <p style={{ textAlign: "justify" }}>
            Per l’utilizzo di questa tipologia di cookie non è necessario richiedere alcun consenso, in quanto questi dispositivi
            hanno unicamente la finalità di rendere più agevole la consultazione del sito o di rispondere a delle esigenze dell’utente
            o di fornire al gestore del sito informazioni da utilizzare poi in forma anonima.
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>Gestione dei cookie nel browser</b>
        </p>
        <p style={{ textAlign: "justify" }}>
            Alcune persone preferiscono non abilitare i cookie e per questo motivo quasi tutti i browser offrono la possibilità
            di gestirli in modo da rispettare le preferenze degli utenti.<br />
            In alcuni browser è possibile impostare regole per gestire i cookie sito per sito, opzione che ti offre un controllo
            più preciso sulla tua privacy. Ciò significa che puoi disabilitare i cookie di tutti i siti, ad eccezione di quelli di cui ti fidi.
            Di seguito alcuni link di riferimento per comprendere la procedura di disattivazione dei cookie nei browser di maggior utilizzo:
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>INTERNET EXPLORER</b><br />
            <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank"
                rel="noopener noreferrer">
                https://support.microsoft.com
            </a>
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>GOOGLE CHROME</b><br />
            <a href="https://support.google.com/accounts/answer/61416?hl=it"
                target="_blank"
                rel="noopener noreferrer">
                https://support.google.com
            </a>
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>APPLE SAFARI</b><br />
            <a href="http://support.apple.com/it-it/HT1677"
                target="_blank"
                rel="noopener noreferrer">
                http://support.apple.com
            </a>
        </p>
        <p style={{ textAlign: "justify" }}>
            <b>MOZILLA FIREFOX</b><br />
            <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                target="_blank"
                rel="noopener noreferrer">
                https://support.mozilla.org
            </a>
        </p>
        <p style={{ textAlign: "justify" }}>
            Tuttavia, disabilitando completamente i cookies nel browser l’utente potrebbe non essere più in
            grado di utilizzare tutte le funzionalità interattive di questo sito.
        </p>
    </>
);

export default Cookies;
